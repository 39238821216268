// autogenerated
import {IIconProps} from "@reapptor-apps/reapptor-react-components";
import {OrderProductStatus, OrderProductErpAction} from "@/models/Enums";
import OrderInfo from "@/models/server/OrderInfo";
import ProductAssortment from "@/models/server/ProductAssortment";
import Product from "@/models/server/Product";
import User from "@/models/server/User";
import OrderProductSelection from "@/models/server/OrderProductSelection";
import Localizer from "@/localization/Localizer";

export default class OrderProduct extends OrderProductSelection {

    public id: string = "";

    public orderId: string = "";

    public order: OrderInfo | null = null;

    public productAssortment: ProductAssortment | null = null;

    public replacementProductAssortment: ProductAssortment | null = null;

    public productReplacementId: string | null = null;

    public fromProductReplacementId: string | null = null;

    public toProductReplacementIds: string[] | null = null;

    public productId: string = "";

    public product: Product | null = null;

    public status: OrderProductStatus = OrderProductStatus.Open;

    public erpAction: OrderProductErpAction | null = null;

    public deliveryDate: Date | null = null;

    public createdAt: Date = new Date();

    public createdById: string = "";

    public createdBy: User | null = null;

    public deliveredQuantity: number = 0;

    public orderLine: string | null = null;

    public manuallyDelivered: boolean = false;

    public manuallyDeliveredComment: string | null = null;

    public prevQuantity: number = 0;

    public submittedQuantity: number = 0;

    public orderQuantity: number | null = null;

    public returnOrderQuantity: number | null = null;

    public expressOrderQuantity: number | null = null;

    public readonly isOrderProduct: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof OrderProduct) || ((from as any).isOrderProduct === true));
    }

    public static as(from?: object | null): OrderProduct | null {
        return (OrderProduct.is(from)) ? from as OrderProduct : null;
    }

    // #region Placeholder

    public static getReplacementId(product: OrderProduct): string | null {
        return ((product.status == OrderProductStatus.Open) || (product.status == OrderProductStatus.Closed))
            ? product.productReplacementId ?? product.fromProductReplacementId
            : product.productReplacementId;
    }

    public static getStatusIcon(model: OrderProduct): IIconProps {
        const open: boolean = model.status == OrderProductStatus.Open;
        const closed: boolean = model.status == OrderProductStatus.Closed;
        const delivered: boolean = model.status == OrderProductStatus.Delivered;
        const submitted: boolean = model.status == OrderProductStatus.Submitted;
        const ignored: boolean = model.status == OrderProductStatus.Ignored;

        return (delivered)
            ? {name: "fas check-circle", className: "text-grey", tooltip: Localizer.enumOrderStatusDelivered}
            : (open)
                ? {name: "fas lock-open-alt", className: "text-success", tooltip: Localizer.enumOrderStatusOpen}
                : (closed)
                    ? {name: "fas fa-clock", className: "text-orange", tooltip: Localizer.enumOrderStatusClosed}
                    : (submitted)
                        ? {name: "fas fa-truck", className: "text-orange", tooltip: Localizer.enumOrderStatusSubmitted}
                        : (ignored)
                            ? {name: "fas ban", className: "text-danger", tooltip: Localizer.enumOrderStatusIgnored}
                            : {name: "fas spinner", className: "text-chocolate", tooltip: Localizer.enumOrderStatusPartiallyDelivered};
    }

    public static getQuantityDifference(product: OrderProduct): string {
        return (product.quantity == product.prevQuantity)
            ? ""
            : (product.quantity > product.prevQuantity)
                ? "+{0}".format(product.quantity - product.prevQuantity)
                : "{0}".format(product.quantity - product.prevQuantity);
    }

    public static getCommentOrNote(product: OrderProduct): string {
        return (product.manuallyDeliveredComment || product.productAssortment?.note || "");
    }

    // #endregion
}