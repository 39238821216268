import React from "react";
import {
    BorderType,
    Carousel,
    CarouselNavigation,
    CarouselPagination,
    ColumnDefinition,
    Grid,
    GridHoveringType,
    Modal,
    ModalSize,
    Spinner,
    Tab,
    TabContainer,
    TabRenderType,
    TwoColumns
} from "@reapptor-apps/reapptor-react-components";
import {BaseComponent, ch, TextAlign} from "@reapptor-apps/reapptor-react-common";
import Product from "@/models/server/Product";
import ImageProvider from "@/providers/ImageProvider";
import ProductAttribute from "@/models/server/ProductAttribute";
import {ProductAccessoryType} from "@/models/Enums";
import ProductAccessory from "@/models/server/ProductAccessory";
import GetProductRequest from "@/models/server/requests/GetProductRequest";
import ProductReplacement from "@/models/server/ProductReplacement";
import GetProductResponse from "@/models/server/responses/GetProductResponse";
import ProductAssortment from "@/models/server/ProductAssortment";
import ListCustomerProductAssortmentsRequest from "@/models/server/requests/ListCustomerProductAssortmentsRequest";
import {SortDirection} from "@reapptor-apps/reapptor-toolkit";
import User from "@/models/server/User";
import ReplacementProductData from "@/models/server/ReplacementProductData";
import Localizer from "@/localization/Localizer";

import styles from "./ProductModal.module.scss";

import noImage from "../Images/NoImage.png";

interface IProductModalProps {
    id?: string;
}

interface IProductModalState {
    product: Product | null;
    replacementData: ReplacementData | null;
    replacementProductData: ReplacementProductData | null;
    replacedProductData: ReplacementProductData[] | null;
    selectedReplacedProductData: ReplacementProductData | null;
    loading: boolean;
    generation: number;
}

class ReplacementData {
    constructor(response: GetProductResponse) {
        this.replacementProductData = response.replacementProductData;
        this.replacedProductData = response.replacedProductData;
    }

    public replacementProductData: ReplacementProductData | null = null;

    public replacedProductData: ReplacementProductData[] | null = null;

    public static hasData(data: ReplacementData | null, replacementProductId: string | null, replacementAssortmentId: string | null): boolean {
        return (
            (!replacementProductId) ||
            ((!!replacementProductId) && (!!data) && (data.replacementProductData?.productReplacement?.id == replacementProductId) && (data.replacementProductData.replacementAssortment?.id == replacementAssortmentId)) &&
            (data.replacedProductData != null)
        );
    }
}

export default class ProductModal extends BaseComponent<IProductModalProps, IProductModalState> {

    state: IProductModalState = {
        product: null,
        replacementData: null,
        replacementProductData: null,
        replacedProductData: null,
        selectedReplacedProductData: null,
        loading: true,
        generation: 0,
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _assortmentsGridRef: React.RefObject<Grid<ProductAssortment>> = React.createRef();
    private readonly _tabContainerRef: React.RefObject<TabContainer> = React.createRef();

    private readonly _columns: ColumnDefinition[] = [
        {
            header: Localizer.productModalGridCustomerGroupLanguageItemName,
            sorting: true,
            isDefaultSorting: true,
            accessor: nameof.full<ProductAssortment>(o => o.customer!.name),
            minWidth: "15rem",
            maxWidth: "15rem",
            settings: {
                infoAccessor: nameof.full<ProductAssortment>(o => o.customer!.customerGroup!.name),
            }
        } as ColumnDefinition,
        {
            header: Localizer.productModalGridCodeLanguageItemName,
            sorting: true,
            isDefaultSorting: true,
            accessor: nameof.full<ProductAssortment>(o => o.customer!.codeInfo),
            minWidth: "5rem",
            maxWidth: "5rem"
        } as ColumnDefinition,
        {
            group: Localizer.productPanelGridThresholdGroupLanguageItemName,
            header: Localizer.productPanelGridThresholdPcsLanguageItemName,
            title: Localizer.productPanelGridThresholdPcsTitleLanguageItemName,
            accessor: (model: ProductAssortment) => ProductAssortment.getNewThresholdPcs(model),
            reRenderRow: true,
            minWidth: "5rem",
            maxWidth: "5rem",
            textAlign: TextAlign.Center,
            settings: {
                min: 0,
                maxLength: 4,
                step: 1,
                infoAccessor: (model: ProductAssortment) => ProductAssortment.getThresholdPcs(model),
                infoHideEqual: true,
            },
        } as ColumnDefinition,
        {
            group: Localizer.productPanelGridThresholdGroupLanguageItemName,
            header: Localizer.productPanelGridThresholdLanguageItemName,
            title: Localizer.productPanelGridThresholdTitleLanguageItemName,
            accessor: nameof.full<ProductAssortment>(o => o.newOrderThreshold),
            minWidth: "5rem",
            maxWidth: "5rem",
            textAlign: TextAlign.Center,
            format: "0.00",
            settings: {
                min: 0,
                maxLength: 4,
                infoAccessor: nameof.full<ProductAssortment>(o => o.orderThreshold),
                infoHideEqual: true,
            }
        } as ColumnDefinition,
        {
            group: Localizer.productPanelGridThresholdGroupLanguageItemName,
            header: Localizer.productPanelGridNewOrderQuantityLanguageItemName,
            title: Localizer.productPanelGridNewOrderQuantityTitleLanguageItemName,
            accessor: nameof.full<ProductAssortment>(o => o.newOrderQuantity),
            minWidth: "5rem",
            maxWidth: "5rem",
            textAlign: TextAlign.Center,
            settings: {
                min: 0,
                maxLength: 4,
                infoAccessor: nameof.full<ProductAssortment>(o => o.orderQuantity),
                infoHideEqual: true,
            }
        } as ColumnDefinition,
        {
            // Consumption (Period)
            group: Localizer.productPanelGridUsageGroupLanguageItemName,
            header: Localizer.productPanelGridPeriodLanguageItemName,
            title: Localizer.productPanelGridPeriodTitleLanguageItemName,
            accessor: (model: ProductAssortment) => "{0:0.00}".format(ProductAssortment.getConsumption(model, model.customer?.consumptionInterval)),
            minWidth: "6.5rem",
            maxWidth: "6.5rem",
            textAlign: TextAlign.Right,
            settings: {
                infoAccessor: (model: ProductAssortment) => Localizer.productPanelGridPeriodInterval.format(model.customer?.consumptionInterval || 1),
            }
        } as ColumnDefinition,
        {
            // Consumption (12m)
            group: Localizer.productPanelGridUsageGroupLanguageItemName,
            header: Localizer.productPanelGridUsageAnnualLanguageItemName,
            title: Localizer.productPanelGridUsageAnnualTitleLanguageItemName,
            accessor: (model: ProductAssortment) => (model.inheritedAnnualValue != null)
                ? "{0}".format(model.inheritedAnnualValue)
                : "-",
            minWidth: "5rem",
            maxWidth: "5rem",
            textAlign: TextAlign.Right,
            settings: {
                infoAccessor: (model: ProductAssortment) => (model.inheritedAnnualDays)
                    ? Localizer.productPanelGridUsageTotalDays.format(model.inheritedAnnualDays)
                    : "",
            }
        } as ColumnDefinition,
    ];

    public async onCloseAsync(): Promise<void> {
        // await this.setState({product: null});
    }

    private get product(): Product | null {
        return this.state.product;
    }

    private get replacementData(): ReplacementData | null {
        return this.state.replacementData;
    }

    private getReplacementValidUntil(replacement: ProductReplacement | null): string {
        if (replacement?.validTo) {
            replacement?.validTo.setHours(23, 59, 59, 999);
        }

        return (replacement)
            ? (replacement.validTo)
                ? Localizer.productModalReplacementValidUntil.format(replacement.validTo)
                : Localizer.productModalReplacementNoValidTo
            : "";
    }

    private hasData(productId: string, productReplacementId: string | null, replacementAssortmentId: string | null): boolean {
        return (
            ((!!this.product) && (this.product.id == productId) && (Product.hasData(this.product))) &&
            (ReplacementData.hasData(this.replacementData, productReplacementId, replacementAssortmentId))
        );
    }

    private get loading(): boolean {
        return this.state.loading;
    }

    private get title(): string {
        return (this.product)
            ? Product.getTitle(this.product)
            : "...";
    }

    private get subtitle(): string {
        return (this.product)
            ? Product.getSubtitle(this.product)
            : Localizer.genericLoading;
    }

    private get relatedProducts(): ProductAccessory[] {
        return this.product?.accessories?.where(item => item.type == ProductAccessoryType.RelatedProduct) ?? [];
    }

    private get correspondingProducts(): ProductAccessory[] {
        return this.product?.accessories?.where(item => item.type == ProductAccessoryType.CorrespondingProduct) ?? [];
    }

    private get modal(): Modal {
        return this._modalRef.current!;
    }

    public get user(): User {
        return ch.getUser();
    }

    public get selectedReplacedProductData(): ReplacementProductData | null {
        return this.state.selectedReplacedProductData;
    }

    public get isMasterOrAdmin(): boolean {
        return (this.user.isMaster || this.user.isAdmin || this.user.isSiteAdmin);
    }

    private async fetchCustomerAssortmentsAsync(sender: Grid<ProductAssortment>, sortColumnName: string | null, sortDirection: SortDirection | null): Promise<ProductAssortment[]> {
        if (this.product != null) {
            const request = new ListCustomerProductAssortmentsRequest();
            request.productId = this.product.id;

            return await sender.postAsync("/api/productManagement/ListCustomerProductAssortments", request);
        }

        return [];
    }

    private async setSelectedReplacedProductDataAsync(item: ReplacementProductData): Promise<void> {
        await this.setState({selectedReplacedProductData: item})
    }

    public async openAsync(productOrId: Product | string, replacementProductId: string | null = null, replacementAssortmentId: string | null = null, customerId: string | null = null, toReplacementProductIds: string[] | null = null, generation: number = 0): Promise<void> {

        await this.modal.openAsync();

        let productId: string;
        let hasData: boolean;

        if (typeof productOrId === "object") {
            productId = productOrId.id;

            hasData = this.hasData(productId, replacementProductId, replacementAssortmentId);

            if (!hasData) {
                this.state.product = productOrId;

                hasData = this.hasData(productId, replacementProductId, replacementAssortmentId);
            }
        } else {
            productId = productOrId;

            hasData = this.hasData(productId, replacementProductId, replacementAssortmentId);
        }

        if (hasData) {
            await this.reRenderAsync();
        } else {

            if (!this.loading) {
                await this.setState({loading: true});
            }

            const request = new GetProductRequest();
            request.productIdOrAssortmentIdOrOrderProductId = productId;
            request.productReplacementId = replacementProductId;
            request.replacementAssortmentId = replacementAssortmentId;
            request.customerId = customerId;
            request.toReplacementProductIds = toReplacementProductIds;

            const response: GetProductResponse = await this.postAsync("/api/productManagement/getProduct", request);

            this.state.product = response.product;

            const replacementData: ReplacementData = new ReplacementData(response);

            this.state.replacementData = replacementData;
            this.state.replacementProductData = replacementData.replacementProductData;
            this.state.replacedProductData = replacementData.replacedProductData;
            this.state.selectedReplacedProductData = ((response.replacedProductData != null) && (response.replacedProductData.length > 0))
                ? response.replacedProductData[0]
                : null;

            await this.setState({loading: false});
        }

        const isNewGeneration: boolean = ((this.state.generation != generation) || (!hasData));

        if (isNewGeneration) {
            this.state.generation = generation;
            await this._assortmentsGridRef.current?.reloadAsync();
        }

        await this._tabContainerRef.current?.activateTabAsync(0);
    }

    public async closeAsync(): Promise<void> {
        await this.modal.closeAsync();
    }

    public get isOpen(): boolean {
        return (this._modalRef.current != null) && (this._modalRef.current.isOpen);
    }

    private renderAttributes(attributes: ProductAttribute[], excludeKeywords: boolean): React.ReactNode {
        if (excludeKeywords) {
            const keywords: ProductAttribute | null = attributes.firstOrDefault(item => item.key == "Keywords");
            if (keywords) {
                attributes.remove(keywords);
            }
        }

        attributes.sortBy(attribute => ProductAttribute.getKey(attribute));

        const description: ProductAttribute | null = attributes.firstOrDefault(item => item.key === "ProductDescription");

        if (description != null) {
            attributes.remove(description);

            attributes = [description, ...attributes];
        }

        return (
            <table className={this.css(styles.attributes, "table table-striped")}>
                <tbody>

                {
                    attributes.map((attribute: ProductAttribute, index) => (
                        <tr id={index.toString()} key={index.toString()} className={this.css(attribute.key == description?.key && styles.description)}>
                            <td className={styles.key}>
                                {ProductAttribute.getKey(attribute)}
                            </td>
                            <td className={styles.value}>

                                <div>

                                    <span> {ProductAttribute.getValue(attribute)}</span>

                                </div>

                            </td>
                        </tr>
                    ))
                }

                </tbody>

            </table>
        );
    }

    private renderProduct(product: Product, index: number, relatedProduct: boolean, excludeKeywords: boolean | null = null): React.ReactElement {

        const attributes: ProductAttribute[] = Product.getAttributes(product);

        return (

            <div className={styles.product} key={index}>

                {
                    (relatedProduct) &&
                    (
                        <div className={styles.caption}>
                            <span>{Product.getTitle(product)}</span>
                            <span>{Product.getSubtitle(product)}</span>
                        </div>
                    )
                }

                <TwoColumns>

                    {
                        (!this.loading)
                            ?
                            (
                                <img src={ImageProvider.getRequiredImageSrc(Product.getImage(product), noImage)}
                                     className={styles.image}
                                     alt={Localizer.productsModalImageNotFound}
                                />
                            )
                            :
                            (
                                <div/>
                            )
                    }

                    {this.renderAttributes(attributes, excludeKeywords ?? relatedProduct)}

                </TwoColumns>
            </div>
        )
    }

    private renderAccessories(accessories: ProductAccessory[], noAccessoriesText: string): React.ReactNode {
        if (this.loading) {
            return (
                <span className={styles.noAccessories}>{Localizer.genericLoading}</span>
            );
        }

        if (accessories.length == 0) {
            return (
                <span className={styles.noAccessories}>{noAccessoriesText}</span>
            );
        }

        const pagination: CarouselPagination = (accessories.length <= 20)
            ? CarouselPagination.BottomOutside
            : CarouselPagination.None;

        return (
            <Carousel navigation={CarouselNavigation.Outside}
                      pagination={pagination}
                      slidesPerView={1}
                      className={styles.carousel}
            >
                {
                    accessories.map((accessory: ProductAccessory, index: number) => this.renderProduct(accessory.relatedProduct!, index, true))
                }
            </Carousel>
        );
    }

    private renderReplacedProducts(): React.ReactNode {
        if (this.loading) {
            return (
                <span className={styles.noAccessories}>{Localizer.genericLoading}</span>
            );
        }

        if ((this.replacementData == null) || (this.replacementData.replacedProductData == null) || (this.replacementData.replacedProductData.length == 0)) {
            return (
                <span className={styles.noAccessories}>{Localizer.productsModalNoReplacedProducts}</span>
            );
        }

        const severalReplacedProducts: boolean = (this.replacementData.replacedProductData.length > 1);

        return (
            <div className={this.css(styles.replacementData, severalReplacedProducts && styles.replacedProductsList)}>
                {
                    (severalReplacedProducts) &&
                    (
                        <div>
                            {
                                this.replacementData.replacedProductData.map((item: ReplacementProductData) =>
                                    (
                                        <div className={this.css(styles.replacedProductsListItem, (item.productReplacement?.fromProductId == this.selectedReplacedProductData?.productReplacement?.fromProductId) && styles.selected)}
                                             onClick={() => this.setSelectedReplacedProductDataAsync(item)}
                                        >

                                            <div className={styles.row1}>

                                                <span>{item.productReplacement?.fromProduct?.code}</span>

                                                <img src={ImageProvider.getRequiredImageSrc(Product.getImage(item.productReplacement!.fromProduct!), noImage)}
                                                     className={styles.image}
                                                     alt={Localizer.productsModalImageNotFound}
                                                />

                                            </div>

                                            <div className={styles.row2}>
                                                
                                                <span>{item.productReplacement?.fromProduct?.name}</span>
                                                
                                            </div>

                                        </div>
                                    ))
                            }
                        </div>
                    )
                }

                {
                    (this.selectedReplacedProductData) &&
                    (
                        <div>
                            {this.renderReplacementInfo(this.selectedReplacedProductData, true)}
                        </div>
                    )
                }
            </div>
        );
    }

    private renderReplacementProduct(): React.ReactNode {
        if (this.loading) {
            return (
                <span className={styles.noAccessories}>{Localizer.genericLoading}</span>
            );
        }

        if ((this.state.replacementProductData == null) || ((this.state.replacementProductData.productReplacement?.toProduct == null) && (this.state.replacementProductData.replacementAssortment?.product == null))) {
            return (
                <span className={styles.noAccessories}>{Localizer.productModalNoReplacement}</span>
            );
        }

        return (
            this.renderReplacementInfo(this.state.replacementProductData, false)
        );
    }

    private renderReplacementInfo(replacementProductData: ReplacementProductData, forReplaced: boolean): React.ReactNode {
        const fullAccess: boolean = this.user.isMediq;

        const limitedAccessStyles: any = (!fullAccess) && (styles.limitedAccess);

        const openedProductInfo: Product | null = (forReplaced)
            ? replacementProductData.productReplacement?.fromProduct || null
            : replacementProductData.productReplacement?.toProduct || null;

        const changedProductInfo: Product | null = (!forReplaced)
            ? replacementProductData.productReplacement?.toProduct || null
            : replacementProductData.productReplacement?.fromProduct || null;
        
        return (
            <div className={this.css(styles.replacement, limitedAccessStyles)}>

                {
                    (fullAccess) &&
                    (
                        <TwoColumns className={"m-0"}>

                            <div className={styles.info}>
                                {
                                    (replacementProductData.productReplacement) &&
                                    (
                                        <React.Fragment>

                                            <span>{this.toMultiLines(this.getReplacementValidUntil(replacementProductData.productReplacement))}</span>
                                            <span>{this.toMultiLines(Localizer.productModalCreatedBy.format(replacementProductData.productReplacement.createdBy, replacementProductData.productReplacement.createdAt))}</span>
                                            {
                                                (openedProductInfo) &&
                                                (
                                                    <span>{this.toMultiLines(Localizer.productModalCode.format(openedProductInfo.code))}</span>
                                                )
                                            }

                                        </React.Fragment>
                                    )
                                }
                            </div>

                            <table className={this.css(styles.consumption, "table table-striped")}>
                                <tbody>
                                <tr>
                                    <td>{Localizer.productModalProduct}</td>
                                    <td>
                                        {Localizer.productAttributeKeyPackageQuantity}
                                    </td>
                                    <td>
                                        {"TP"}
                                    </td>
                                    <td>
                                        {"TM"}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {Localizer.productModalOriginal}
                                    </td>
                                    <td>
                                        {replacementProductData.productReplacement?.fromProduct?.packageQuantity}
                                    </td>
                                    <td>
                                        {replacementProductData.replacementFromOrderThreshold}
                                    </td>
                                    <td>
                                        {replacementProductData.replacementFromOrderQuantity}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {Localizer.productModalReplacement}
                                    </td>
                                    <td>
                                        {replacementProductData.productReplacement?.toProduct?.packageQuantity}
                                    </td>
                                    <td>
                                        {replacementProductData.replacementToOrderThreshold}
                                    </td>
                                    <td>
                                        {replacementProductData.replacementToOrderQuantity}
                                    </td>
                                </tr>

                                </tbody>

                            </table>

                        </TwoColumns>
                    )
                }

                {
                    (changedProductInfo) &&
                    (
                        this.renderProduct(changedProductInfo, 0, false, true)
                    )
                }

            </div>
        );
    }

    private renderCustomerAssortments(): React.ReactNode {
        return (
            <Grid autoToggle responsive
                  ref={this._assortmentsGridRef}
                  minWidth="auto"
                  hovering={GridHoveringType.Row}
                  noDataText={Localizer.genericNoData}
                  headerMinHeight={80}
                  borderType={BorderType.NoSeparators}
                  columns={this._columns}
                  fetchData={(sender: Grid<ProductAssortment>, pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection) => this.fetchCustomerAssortmentsAsync(sender, sortColumnName, sortDirection)}
            />
        );
    }

    public render(): React.ReactNode {

        const tabContainerId: string = `${this.id}_tabContainer_${this.product?.id}`;

        return (
            <Modal notResponsive
                   id={this.id}
                   ref={this._modalRef}
                   className={styles.productModal}
                   title={this.title}
                   subtitle={this.subtitle}
                   size={ModalSize.ExtraLarge}
                   onClose={() => this.onCloseAsync()}
            >

                {
                    (this.isOpen) &&
                    (
                        <div className={styles.content}>

                            {
                                (this.product) &&
                                (
                                    <TabContainer scale
                                                  id={tabContainerId}
                                                  key={tabContainerId}
                                                  ref={this._tabContainerRef}
                                                  renderType={TabRenderType.Once}
                                                  dataStorageType={false}
                                    >

                                        <Tab id="imageAndAttributes" title={Localizer.productsModalImageAndAttributes}>

                                            {
                                                this.renderProduct(this.product, 0, false)
                                            }

                                        </Tab>

                                        <Tab id="relatedProduct" title={Localizer.productsModalRelatedProducts}>

                                            {this.renderAccessories(this.relatedProducts, Localizer.productsModalNoRelatedProducts)}

                                        </Tab>

                                        <Tab id="correspondingProducts" title={Localizer.productsModalCorrespondingProducts}>

                                            {this.renderAccessories(this.correspondingProducts, Localizer.productsModalNoCorrespondingProducts)}

                                        </Tab>

                                        <Tab id="replacedProducts" title={Localizer.productsModalReplacedProducts}>

                                            {this.renderReplacedProducts()}

                                        </Tab>

                                        <Tab id="replacementProduct" title={Localizer.productModalReplacementProduct}>

                                            {this.renderReplacementProduct()}

                                        </Tab>

                                        {
                                            (this.isMasterOrAdmin) &&
                                            (
                                                <Tab id="customerAssortments" title={Localizer.productModalCustomerAssortments}>

                                                    {this.renderCustomerAssortments()}

                                                </Tab>
                                            )
                                        }

                                    </TabContainer>
                                )
                            }

                            {(this.loading) && <Spinner/>}

                        </div>
                    )
                }

            </Modal>
        )
    }
}