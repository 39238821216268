// autogenerated
import {ETagRequest} from "@reapptor-apps/reapptor-react-common";

export default class GetProductRequest extends ETagRequest {

    public productIdOrAssortmentIdOrOrderProductId: string = "";

    public customerId: string | null = null;

    public toReplacementProductIds: string[] | null = null;

    public productReplacementId: string | null = null;

    public replacementAssortmentId: string | null = null;

    public readonly isGetProductRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof GetProductRequest) || ((from as any).isGetProductRequest === true));
    }

    public static as(from?: object | null): GetProductRequest | null {
        return (GetProductRequest.is(from)) ? from as GetProductRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}