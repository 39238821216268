// autogenerated
import {ProductReplacementType} from "@/models/Enums";

export default class ProductReplacementInfo {

    public id: string = "";

    public customerGroupName: string | null = null;

    public customerName: string | null = null;

    public fromProductName: string | null = null;

    public fromProductCode: string | null = null;

    public toProductName: string | null = null;

    public toProductCode: string | null = null;

    public type: ProductReplacementType = ProductReplacementType.Temporary;

    public createdByName: string | null = null;

    public readonly isProductReplacementInfo: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ProductReplacementInfo) || ((from as any).isProductReplacementInfo === true));
    }

    public static as(from?: object | null): ProductReplacementInfo | null {
        return (ProductReplacementInfo.is(from)) ? from as ProductReplacementInfo : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}