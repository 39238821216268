import React from "react";
import {ActionType, BaseComponent, ch, IConfirmation, TextAlign} from "@reapptor-apps/reapptor-react-common";
import Order from "@/models/server/Order";
import {BorderType, CellAction, CellModel, ColumnActionDefinition, ColumnDefinition, ColumnType, Grid, GridHoveringType, GridModel, GridOddType, RowModel} from "@reapptor-apps/reapptor-react-components";
import {IPagedList, SortDirection} from "@reapptor-apps/reapptor-toolkit";
import ListOrdersRequest from "@/models/server/requests/ListOrdersRequest";
import OrderProduct from "@/models/server/OrderProduct";
import OrdersHistoryToolbarModel from "@/pages/OrderManagement/OdersHistory/OrdersHistoryToolbar/OrdersHistoryToolbarModel";
import {ExportEncodingType, OrderProductErpAction, OrderProductStatus, OrderStatus} from "@/models/Enums";
import OrdersHistoryToolbar from "@/pages/OrderManagement/OdersHistory/OrdersHistoryToolbar/OrdersHistoryToolbar";
import User from "@/models/server/User";
import ProductModal from "@/components/ProductModal/ProductModal";
import Product from "@/models/server/Product";
import DeliveryOrderProductRequest from "@/models/server/requests/DeliveryOrderProductRequest";
import DeliveryOrderProductResponse from "@/models/server/responses/DeliveryOrderProductResponse";
import AittaController from "@/pages/AittaController";
import Localizer from "@/localization/Localizer";

import styles from "./OrdersHistoryPanel.module.scss";
import DeliveryOrderRequest from "@/models/server/requests/DeliveryOrderRequest";
import DeliveryOrderResponse from "@/models/server/responses/DeliveryOrderResponse";
import ResetOrderRequest from "@/models/server/requests/ResetOrderRequest";
import ResetOrderResponse from "@/models/server/responses/ResetOrderResponse";

export interface IOrdersHistoryPanelProps {
    exportOrderToCsv(order: Order, encodingType: ExportEncodingType): Promise<void>;
    exportOrderToXml(order: Order): Promise<void>;
    sendOrder(order: Order, close: boolean): Promise<void>;
}

interface IOrdersHistoryPanelState {
    toolbar: OrdersHistoryToolbarModel;
    orders: Order[];
}

export default class OrdersHistoryPanel extends BaseComponent<IOrdersHistoryPanelProps, IOrdersHistoryPanelState> {

    state: IOrdersHistoryPanelState = {
        toolbar: new OrdersHistoryToolbarModel(),
        orders: [],
    };

    private readonly _ordersPanelGridRef: React.RefObject<Grid<Order>> = React.createRef();
    private readonly _productModelRef: React.RefObject<ProductModal> = React.createRef();

    private readonly _columns: ColumnDefinition[] = [
        {
            header: Localizer.orderHistoryPanelGridOrderLanguageItemName,
            sorting: SortDirection.Desc,
            isDefaultSorting: true,
            className: styles.name,
            accessor: nameof<Order>(o => o.number),
            format: (value: number) => "#{0}".format(value),
            minWidth: "6rem",
            maxWidth: "6rem",
            textAlign: TextAlign.Left,
            init: (cell: CellModel<Order>) => this.initOrderNumberColumn(cell),
            actions: [
                {
                    name: "toggle",
                    type: ActionType.Blue,
                    icon: {name: ""},
                    callback: (cell: CellModel<Order>) => this.toggleDetailsAsync(cell),
                } as ColumnActionDefinition,
            ],
            callback: (cell: CellModel<Order>) => this.toggleDetailsAsync(cell),
        } as ColumnDefinition,
        {
            header: Localizer.orderHistoryPanelGridCustomerGroupLanguageItemName,
            sorting: true,
            accessor: nameof.full<Order>(o => o.customer!.customerGroup!.name),
            className: styles.wordWrap,
            minWidth: "6rem",
            maxWidth: "6rem",
        } as ColumnDefinition,
        {
            header: Localizer.orderHistoryPanelGridCustomerLanguageItemName,
            stretch: true,
            sorting: true,
            accessor: nameof.full<Order>(o => o.customer!.name),
            minWidth: "12rem",
            settings: {
                infoAccessor: nameof.full<Order>(o => o.customer!.codeInfo),
            }
        } as ColumnDefinition,
        {
            header: Localizer.orderHistoryPanelGridDeliveryAddressLanguageItemName,
            sorting: false,
            accessor: (model: Order) => Order.deliveryAddress(model),
            className: styles.wordWrap,
            minWidth: "18rem",
            maxWidth: "18rem",
            textAlign: TextAlign.Left,
        } as ColumnDefinition,
        {
            header: Localizer.orderHistoryPanelGridErpNumberLanguageItemName,
            sorting: true,
            accessor: nameof.full<Order>(o => o.erpId),
            minWidth: "7rem",
            maxWidth: "7rem",
            textAlign: TextAlign.Center
        } as ColumnDefinition,
        {
            header: Localizer.orderHistoryPanelGridTypeLanguageItemName,
            name: nameof<Order>(o => o.express),
            type: ColumnType.Icon,
            sorting: true,
            accessor: (model: Order) => Order.getExpressIcon(model),
            minWidth: "7rem",
            maxWidth: "7rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.orderHistoryPanelGridStatusLanguageItemName,
            name: nameof<Order>(o => o.status),
            type: ColumnType.Icon,
            sorting: true,
            accessor: (model) => Order.getStatusIcon(model),
            minWidth: "7rem",
            maxWidth: "7rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.genericCreatedAtLanguageItemName,
            sorting: true,
            accessor: nameof.full<Order>(o => o.visualCreatedAt),
            format: "D",
            minWidth: "7rem",
            maxWidth: "7rem",
            className: styles.quantity,
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.orderHistoryPanelGridClosingLanguageItemName,
            sorting: true,
            accessor: nameof.full<Order>(o => o.closedAt),
            format: "D",
            minWidth: "7rem",
            maxWidth: "7rem",
            className: styles.quantity,
            textAlign: TextAlign.Center,
            settings: {
                infoAccessor: nameof.full<Order>(o => o.plannedCloseDate),
                infoFormat: "D",
                infoHideEqual: true
            }
        } as ColumnDefinition,
        {
            header: Localizer.orderHistoryPanelGridPartialDeliveryLanguageItemName,
            sorting: true,
            accessor: nameof.full<Order>(o => o.partiallyDeliveredAt),
            format: "D",
            minWidth: "7rem",
            maxWidth: "7rem",
            className: styles.quantity,
            textAlign: TextAlign.Center
        } as ColumnDefinition,
        {
            header: Localizer.orderHistoryPanelGridDeliveryLanguageItemName,
            sorting: true,
            accessor: nameof.full<Order>(o => o.deliveredAt),
            format: "D",
            minWidth: "7rem",
            maxWidth: "7rem",
            className: styles.quantity,
            textAlign: TextAlign.Center,
            settings: {
                infoAccessor: nameof.full<Order>(o => o.plannedDeliveryDate),
                infoFormat: "D",
                infoHideEqual: true
            }
        } as ColumnDefinition,
        {
            header: Localizer.genericActionsLanguageItemName,
            minWidth: "7.5rem",
            maxWidth: "7.5rem",
            removable: false,
            init: (cell: CellModel<Order>) => this.initOrderActions(cell),
            actions: [
                {
                    name: "excel",
                    title: Localizer.orderHistoryPanelGridDownloadExcelLanguageItemName,
                    icon: "far fa-file-excel",
                    type: ActionType.Default,
                    callback: (cell: CellModel<Order>) => this.exportOrderToCsvAsync(cell)
                } as ColumnActionDefinition,
                {
                    name: "xml",
                    title: Localizer.orderHistoryPanelGridDownloadXmlLanguageItemName,
                    icon: "far fa-file-alt",
                    type: ActionType.Default,
                    callback: (cell: CellModel<Order>) => this.exportOrderToXmlAsync(cell)
                } as ColumnActionDefinition,
                {
                    name: "close",
                    title: Localizer.orderHistoryPanelGridCloseOrderLanguageItemName,
                    icon: "fa-lock fa-lg fal",
                    type: ActionType.Create,
                    right: true,
                    confirm: (cell: CellModel<Order>) => Localizer.orderHistoryPanelGridSureCloseOrder.format(cell.model.number),
                    callback: (cell: CellModel<Order>) => this.sendOrderAsync(cell, true)
                } as ColumnActionDefinition,
                {
                    name: "send",
                    title: Localizer.orderHistoryPanelGridSendOrderLanguageItemName,
                    icon: "fa-share-square fa-lg fal",
                    type: ActionType.Create,
                    right: true,
                    confirm: (cell: CellModel<Order>) => Localizer.orderHistoryPanelGridSureSendOrder.format(cell.model.number),
                    callback: (cell: CellModel<Order>) => this.sendOrderAsync(cell, false)
                } as ColumnActionDefinition,
                {
                    name: "delivery",
                    title: Localizer.orderHistoryPanelGridOrderDeliveryTitleLanguageItemName,
                    icon: "fad fa-window-restore",
                    type: ActionType.Create,
                    right: true,
                    confirm: {
                        title: Localizer.orderHistoryPanelGridDeliveryConfirmLanguageItemName,
                        comment: true,
                        minLength: 5,
                        placeholder: Localizer.orderHistoryPanelGridDeliveryPlaceholderLanguageItemName
                    } as IConfirmation,
                    callback: (cell, _, __?: string | null, data?: string | null) => this.deliveryOrderAsync(cell, data)
                } as ColumnActionDefinition,
                {
                    name: "reset",
                    title: Localizer.orderHistoryPanelGridOrderResetTitleLanguageItemName,
                    icon: "fad fa-arrow-to-left",
                    type: ActionType.Delete,
                    right: true,
                    confirm: Localizer.orderHistoryPanelGridOrderResetConfrimLanguageItemName,
                    callback: (cell) => this.resetOrderAsync(cell)
                } as ColumnActionDefinition
            ]
        } as ColumnDefinition,
    ];

    private readonly _orderProductColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: nameof.full<OrderProduct>(o => o.orderLine),
            minWidth: "3rem",
            maxWidth: "3rem"
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridProductGroupThreeLevelLanguageItemName,
            minWidth: "15rem",
            maxWidth: "15rem",
            render: (cell: CellModel<OrderProduct>) => this.renderGroupCell(cell)
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridMediqNumberLanguageItemName,
            accessor: (model: OrderProduct) => (model.replacementProductAssortment ?? model.productAssortment)!.product?.code,
            minWidth: "7.5rem",
            maxWidth: "7.5rem",
            textAlign: TextAlign.Center,
            init: (cell: CellModel<any>) => this.initCodeCell(cell),
            settings: {
                infoAccessor: nameof.full<OrderProduct>(o => o.productAssortment!.product!.code),
                infoHideEqual: true,
                descriptionIcon: "far fa-exchange-alt",
                descriptionAccessor: () => "",
            },
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridNameLanguageItemName,
            name: nameof.full<OrderProduct>(o => o.productAssortment!.product!.name),
            accessor: (model: OrderProduct) => Product.getFullName(model.productAssortment!.product!),
            minWidth: "25rem",
            //stretch: true,
            className: this.css(styles.hasImage, styles.wordWrap),
            init: (cell: CellModel<OrderProduct>) => this.initNameCell(cell),
            callback: (cell: CellModel<OrderProduct>) => this.onNameClickAsync(cell),
            settings: {
                descriptionAccessor: (model: OrderProduct) => OrderProduct.getCommentOrNote(model),
            },
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridManufacturerCodeLanguageItemName,
            accessor: nameof.full<OrderProduct>(o => o.productAssortment!.product!.manufactureCode),
            minWidth: "10rem",
            maxWidth: "10rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridPackageQuantityLanguageItemName,
            accessor: (model: OrderProduct) => (model.replacementProductAssortment ?? model.productAssortment)!.product!.packageQuantity,
            minWidth: "6rem",
            maxWidth: "6rem",
            textAlign: TextAlign.Center,
            settings: {
                infoAccessor: (model: OrderProduct) => model.productAssortment!.product!.packageQuantity,
                infoHideEqual: true,
            }
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridOrderQuantityLanguageItemName,
            accessor: (model: OrderProduct) => this.getDeliveredQuantityInfo(model),
            minWidth: "2rem",
            maxWidth: "2rem",
            textAlign: TextAlign.Center,
            settings: {
                infoAccessor: nameof.full<OrderProduct>(o => o.quantity),
                infoHideEqual: true
            }
        } as ColumnDefinition,
        {
            header: Localizer.orderHistoryPanelGridStatusLanguageItemName,
            type: ColumnType.Icon,
            accessor: (model: OrderProduct) => OrderProduct.getStatusIcon(model),
            minWidth: "4rem",
            maxWidth: "4rem",
            init: (cell: CellModel<OrderProduct>) => this.initStatusCell(cell),
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.orderHistoryPanelGridErpActionLanguageItemName,
            accessor: nameof.full<OrderProduct>(o => o.erpAction),
            format: nameof<OrderProductErpAction>(),
            minWidth: "6rem",
            maxWidth: "6rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.orderHistoryPanelGridDeliveryDateLanguageItemName,
            accessor: (model: OrderProduct) => model.deliveryDate,
            format: "D",
            minWidth: "5rem",
            maxWidth: "5rem",
            textAlign: TextAlign.Left,
        } as ColumnDefinition,
        {
            header: Localizer.genericActionsLanguageItemName,
            minWidth: 80,
            removable: false,
            textAlign: TextAlign.Center,
            visible: AittaController.hasMasterAccess,
            init: (cell: CellModel<OrderProduct>) => this.initOrderProductOperations(cell),
            actions: [
                {
                    name: "delivery",
                    title: Localizer.orderHistoryPanelGridDeliveryTitleLanguageItemName,
                    icon: "fad fa-window-restore",
                    type: ActionType.Create,
                    confirm: {
                        title: Localizer.orderHistoryPanelGridDeliveryConfirmLanguageItemName,
                        comment: true,
                        minLength: 5,
                        placeholder: Localizer.orderHistoryPanelGridDeliveryPlaceholderLanguageItemName
                    } as IConfirmation,
                    callback: (cell, action, selectedAction?: string | null, data?: string | null) => this.processOperationsAsync(cell, action, data)
                } as ColumnActionDefinition
            ]

        } as ColumnDefinition,
    ];

    private getDeliveredQuantityInfo(model: OrderProduct): string {
        if ((this.isAdmin) && (model.returnOrderQuantity != null)) {
            return `${model.deliveredQuantity} (${model.returnOrderQuantity})`;
        }

        return `${model.deliveredQuantity}`; 
    }
    
    private initOrderProductOperations(cell: CellModel<OrderProduct>): void {
        const model: OrderProduct = cell.row.model;

        const markAsDeliveredAction: CellAction<OrderProduct> = cell.actions[0];

        markAsDeliveredAction.visible = ((model.status == OrderProductStatus.Submitted) || (model.status == OrderProductStatus.PartiallyDelivered));
    }
    
    private async processOperationsAsync(cell: CellModel<OrderProduct>, action: CellAction<OrderProduct>, data?: string | null): Promise<void> {
        const model: OrderProduct = cell.model;

        if (action.action.name === "delivery") {

            const request = new DeliveryOrderProductRequest();
            request.orderProductId = model.id;
            request.comment = data || "";

            const response: DeliveryOrderProductResponse = await cell.grid.postAsync("/api/orderManagement/deliveryOrderProduct", request);

            if (response.orderStatusModified) {
                await this.reloadAsync();
                await ch.flyoutWarningAsync(Localizer.orderHistoryPanelGridDeliveryWarningLanguageItemName);
            } else {
                cell.row.model = response.orderProduct!;
                
                await cell.row.bindAsync();
                
                await ch.flyoutMessageAsync(Localizer.orderHistoryPanelGridDeliveryMessageLanguageItemName);
            }
            
            return;
        }
    }

    private async exportOrderToCsvAsync(cell: CellModel<Order>, encodingType: ExportEncodingType = ExportEncodingType.Utf8) {
        const model: Order = cell.model;
        
        await this.props.exportOrderToCsv(model, encodingType);
    }

    private async exportOrderToXmlAsync(cell: CellModel<Order>): Promise<void> {
        const model: Order = cell.model;
        
        await this.props.exportOrderToXml(model);
    }

    private async sendOrderAsync(cell: CellModel<Order>, close: boolean): Promise<void> {
        const model: Order = cell.model;
        
        await this.props.sendOrder(model, close);
        
        await this.reloadAsync();
    }
    
    private async deliveryOrderAsync(cell: CellModel<Order>, reason?: string | null): Promise<void> {
        const model: Order = cell.model;
        
        const request = new DeliveryOrderRequest();
        request.orderId = model.id;
        request.comment = reason || "";

        const response: DeliveryOrderResponse = await cell.grid.postAsync("/api/orderManagement/deliveryOrder", request);

        cell.row.model = response.order!;

        await cell.row.bindAsync();

        await ch.flyoutMessageAsync(Localizer.orderHistoryPanelGridOrderDeliveryMessage);
    }
    
    private async resetOrderAsync(cell: CellModel<Order>): Promise<void> {
        const model: Order = cell.model;
        
        const request = new ResetOrderRequest();
        request.orderId = model.id;

        const response: ResetOrderResponse = await cell.grid.postAsync("/api/orderManagement/resetOrder", request);

        cell.row.model = response.order!;

        await cell.row.bindAsync();

        await ch.flyoutMessageAsync(Localizer.orderHistoryPanelGridOrderResetMessage);
    }
    
    private initOrderActions(cell: CellModel<Order>): void {
        const model: Order = cell.row.model;
        const user: User = ch.getUser();
        const hasMasterAccess: boolean = user.isMaster || user.isAdmin || user.isSiteAdmin;
        const hasSiteAdminAccess: boolean = user.isSiteAdmin;

        const exportToCsvAction: CellAction<Order> = cell.actions[0];
        const exportToXmlAction: CellAction<Order> = cell.actions[1];
        const closeOrderAction: CellAction<Order> = cell.actions[2];
        const sendOrderAction: CellAction<Order> = cell.actions[3];
        const deliveryAction: CellAction<Order> = cell.actions[4];
        const resetAction: CellAction<Order> = cell.actions[5];

        exportToCsvAction.visible = (!!model.products && model.products.length > 0);
        exportToXmlAction.visible = (user.isMediq) && (!!model.products && model.products.length > 0);
        closeOrderAction.visible = (model.status == OrderStatus.Open) && (!model.express) && (!!model.products && model.products.length > 0);
        sendOrderAction.visible = (model.status == OrderStatus.Open) && ((model.express) || (hasMasterAccess)) && (!!model.products && model.products.length > 0);
        deliveryAction.visible = (hasSiteAdminAccess) && ((model.status == OrderStatus.Submitted) || (model.status == OrderStatus.PartiallyDelivered));
        resetAction.visible = (hasSiteAdminAccess) && (model.status == OrderStatus.Submitted);
    }
    
    private initOrderNumberColumn(cell: CellModel<Order>): void {

        const toggleButton: CellAction<Order> = cell.actions[0];

        if (toggleButton.action.icon) {
            toggleButton.action.icon.name = (cell.row.expanded) ? "far fa-angle-up" : "far fa-angle-down";
        }
    }

    private async onToolbarSubmitAsync(toolbar: OrdersHistoryToolbarModel): Promise<void> {
        this.state.toolbar = toolbar;
        await this.reloadAsync();
    }

    private async fetchOrdersAsync(sender: Grid<Order>, pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null): Promise<IPagedList<Order>> {

        if (!OrdersHistoryToolbarModel.initialized(this.toolbar)) {
            return [].toPagedList(1, pageSize);
        }

        const supportsExpressOrderHistory = (!this.isShelverPartner);
        
        const request = new ListOrdersRequest();
        request.search = this.search;
        request.sortColumnName = sortColumnName;
        request.sortDirection = sortDirection;
        request.customerId = this.customerId;
        request.customerGroupId = this.customerGroupId;
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;
        request.statuses = this.toolbar.orderStatusFilter;
        request.withReturns = this.toolbar.withReturns;
        request.express = supportsExpressOrderHistory && this.toolbar.express;

        return await sender.postAsync("/api/mobileApp/listOrders", request);
    }

    private async toggleDetailsAsync(cell: CellModel<Order>): Promise<void> {
        await cell.row.toggleAsync();
    }

    private initCodeCell(cell: CellModel<OrderProduct>): void {
        const model: OrderProduct = cell.model;

        const replacementProductId: string | null = OrderProduct.getReplacementId(model)

        const canShowReplacementInfo: boolean = (!AittaController.user.isMaster);
        const hasReplacement: boolean = (canShowReplacementInfo) && ((replacementProductId != null) || (model.replacementProductAssortmentId != model.productReplacementId));
        const hasToReplacement: boolean = (canShowReplacementInfo) && ((model.toProductReplacementIds != null) && (model.toProductReplacementIds.length > 0));

        cell.className = this.cssIf(cell.className, ((!hasReplacement) && (!hasToReplacement)), styles.noReplacement);
        cell.className = this.cssIf(cell.className, hasReplacement, styles.hasReplacement);
        cell.className = this.cssIf(cell.className, hasToReplacement, styles.hasToReplacement);
    }

    private initNameCell(cell: CellModel<OrderProduct>): void {
        const model: OrderProduct = cell.row.model;

        const hasImage: boolean = ((model.product != null) && (model.product.images != null) && (model.product.images.length > 0));

        if (hasImage) {
            cell.className = styles.hasImage;
        }

        if (cell.descriptionAction) {
            const commentOrNote: string = OrderProduct.getCommentOrNote(model);
            cell.descriptionAction.visible = (!!commentOrNote);
            cell.descriptionAction.readonly = true;
        }
    }

    private initStatusCell(cell: CellModel<OrderProduct>): void {
        const model: OrderProduct = cell.row.model;

        const displayRefund: boolean = ((this.isAdmin) && (model.returnOrderQuantity != null));

        if (displayRefund) {
            cell.className = styles.refund;
        }
    }

    private async onNameClickAsync(cell: CellModel<OrderProduct>): Promise<void> {
        const model: OrderProduct = cell.row.model;

        if (this._productModelRef.current) {
            const productOrId: Product | string = model.product ?? model.productAssortmentId;
            const replacementProductAssortmentId: string | null = model.replacementProductAssortmentId;
            const replacementProductId: string | null = OrderProduct.getReplacementId(model);

            await this._productModelRef.current.openAsync(productOrId, replacementProductId, replacementProductAssortmentId, this.customerId, model.toProductReplacementIds);
        }
    }

    private initRow(row: RowModel<OrderProduct>): void {
        const model: OrderProduct = row.model;

        const important: boolean = (model.status == OrderProductStatus.Open || model.status == OrderProductStatus.Closed);

        row.className = this.cssIf(row.className, important, styles.important);
    }

    public async reloadAsync(): Promise<void> {
        await this.grid.reloadAsync();
    }

    public get grid(): GridModel<Order> {
        return this._ordersPanelGridRef.current!.model;
    }

    public get customerId(): string | null {
        return this.toolbar.customer?.id || null;
    }

    public get customerGroupId(): string | null {
        return this.toolbar.customerGroup?.id || null;
    }

    public get search(): string | null {
        return this.toolbar.search;
    }

    public get toolbar(): OrdersHistoryToolbarModel {
        return this.state.toolbar;
    }

    public get isShelverPartner(): boolean {
        const user: User = ch.getUser();
        
        return user.isShelverPartner;
    }
    
    public get user(): User {
        return ch.getUser();
    }

    public get isAdmin(): boolean {
        return (this.user.isAdmin) || (this.user.isSiteAdmin);
    }

    public renderDetails(row: RowModel<Order>): React.ReactNode {
        return (
            <Grid id={`grid_products_${row.index}`}
                  noDataText={Localizer.genericNoData}
                  className={styles.orderProductPanelGrid}
                  columns={this._orderProductColumns}
                  borderType={BorderType.NoSeparators}
                  minWidth={"auto"}
                  data={row.model.products}
            />
        );
    }

    public renderGroupCell(cell: CellModel<OrderProduct>): React.ReactNode {
        const product: Product = cell.model!.productAssortment!.product!;

        const mainGroup: string = product.mainGroup ?? "";
        const subGroup: string = product.subGroup ?? "";
        const subSubGroup: string = product.subSubGroup ?? "";

        cell.className = this.css(styles.productGroup, styles.threeProductGroups)

        return (
            <div>

                {
                    (mainGroup) && (
                        <span>{mainGroup}</span>
                    )
                }

                {
                    (subGroup) && (
                        <span>{subGroup}</span>
                    )
                }

                {
                    (subSubGroup) &&
                    (
                        <span>{subSubGroup}</span>
                    )
                }

            </div>
        );
    }
    
    public render(): React.ReactNode {
        return (
            <div id={this.id} className={this.css(styles.ordersHistoryPanel)}>

                <OrdersHistoryToolbar model={this.toolbar}
                                      className={styles.stickyToolbar}
                                      showOrderTypeFilter={!this.isShelverPartner}
                                      showWithReturnsFilter={this.isAdmin}
                                      hideCustomersInSearch={(AittaController.user.isMaster)}
                                      onChange={(sender: OrdersHistoryToolbar, toolbar: OrdersHistoryToolbarModel) => this.onToolbarSubmitAsync(toolbar)}
                />

                <Grid optimization responsive
                      id={"ordersPanelGrid"}
                      ref={this._ordersPanelGridRef}
                      pagination={{pageSize: 50}}
                      minWidth="auto"
                      hovering={GridHoveringType.Row}
                      className={this.css(styles.orderPanelGrid, styles.stickyHeader)}
                      headerMinHeight={80}
                      odd={GridOddType.None}
                      borderType={BorderType.NoSeparators}
                      columns={this._columns}
                      noDataText={Localizer.orderHistoryPanelGridNoOrders}
                      initRow={(row: RowModel<OrderProduct>) => this.initRow(row)}
                      renderDetails={(row: RowModel<Order>) => this.renderDetails(row)}
                      fetchData={(sender: Grid<Order>, pageNumber, pageSize, sortColumnName, sortDirection) => this.fetchOrdersAsync(sender, pageNumber, pageSize, sortColumnName, sortDirection)}
                />

                <ProductModal id={"productModal"}
                              ref={this._productModelRef}
                />

            </div>
        )
    }
}