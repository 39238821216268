import React from "react";
import {ActionType, BaseComponent, ch, IBaseComponentProps, IConfirmation, TextAlign} from "@reapptor-apps/reapptor-react-common";
import ProductsToolbar from "@/pages/ProductManagement/ProductsToolbar/ProductsToolbar";
import {BorderType, CellAction, CellModel, ColumnActionDefinition, ColumnDefinition, ColumnType, Grid, GridHoveringType, GridModel, GridOddType, RowModel} from "@reapptor-apps/reapptor-react-components";
import {IPagedList, SortDirection, Utility} from "@reapptor-apps/reapptor-toolkit";
import ProductsToolbarModel, {ProductPanelType} from "@/pages/ProductManagement/ProductsToolbar/ProductsToolbarModel";
import ProductReplacement from "@/models/server/ProductReplacement";
import ListProductReplacementsRequest from "@/models/server/requests/ListProductReplacementsRequest";
import SaveProductReplacementRequest from "@/models/server/requests/SaveProductReplacementRequest";
import ProductReplacementModal from "@/pages/ProductManagement/ProductReplacementsPanel/ProductReplacementsModal/ProductReplacementsModal";
import ProductReplacementItem from "@/pages/ProductManagement/ProductReplacementsPanel/ProductReplacementItem";
import SaveProductReplacementResponse from "@/models/server/responses/SaveProductReplacementResponse";
import DeleteProductReplacementRequest from "@/models/server/requests/DeleteProductReplacementRequest";
import {ProductReplacementLevel, ProductReplacementType} from "@/models/Enums";
import Product from "@/models/server/Product";
import Localizer from "@/localization/Localizer";

import styles from "./ProductReplacementsPanel.module.scss";
import AittaController from "@/pages/AittaController";
import DeleteProductReplacementResponse from "@/models/server/responses/DeleteProductReplacementResponse";

export interface IProductReplacementsPanelProps extends IBaseComponentProps {
}

interface IProductReplacementsPanelState {
    toolbar: ProductsToolbarModel;
}

export default class ProductReplacementsPanel extends BaseComponent<IProductReplacementsPanelProps, IProductReplacementsPanelState> {

    state: IProductReplacementsPanelState = {
        toolbar: new ProductsToolbarModel(),
    };

    private readonly _replacementsGridRef: React.RefObject<Grid<ProductReplacementItem>> = React.createRef();
    private readonly _replacementModalRef: React.RefObject<ProductReplacementModal> = React.createRef();

    private readonly _columns: ColumnDefinition[] = [
        {
            header: Localizer.productReplacementPageGridValidToLanguageItemName,
            sorting: true,
            editable: true,
            type: ColumnType.Date,
            accessor: nameof.full<ProductReplacementItem>(o => o.owner.validTo),
            format: "D",
            minWidth: "8rem",
            init: (cell: CellModel<ProductReplacementItem>) => this.initValidTo(cell),
            settings: {
                min: Utility.today(),
            },
            actions: [
                {
                    name: "resetValidTo",
                    title: Localizer.productReplacementPageGridResetExpirationDateLanguageItemName,
                    icon: "fal minus",
                    type: ActionType.Delete,
                    callback: (cell: CellModel<ProductReplacementItem>) => this.resetValidToAsync(cell)
                } as ColumnActionDefinition,
            ]
        } as ColumnDefinition,
        {
            header: Localizer.productReplacementPageGridOwnerLanguageItemName,
            minWidth: "15rem",
            maxWidth: "15rem",
            accessor: (model: ProductReplacementItem) => (model.customerGroup != null)
                ? model.customerGroup.name
                : (model.customer != null)
                    ? model.customer.name
                    : "—",
            init: (cell: CellModel<ProductReplacementItem>) => this.initOwner(cell),
        } as ColumnDefinition,
        {
            header: Localizer.productReplacementPageGridTypeLanguageItemName,
            textAlign: TextAlign.Center,
            minWidth: "7rem",
            maxWidth: "7rem",
            accessor: (model: ProductReplacementItem) => (model.owner.type == ProductReplacementType.Permanent)
                ? (model.owner.generateLabels)
                    ? Localizer.productReplacementPageGridTypePermanent + "\n" + Localizer.productReplacementPageGridTypeGenerateLabels
                    : Localizer.productReplacementPageGridTypePermanent + "\n" + Localizer.productReplacementPageGridTypeNoLabels
                : (model.owner.generateLabels)
                    ? Localizer.productReplacementPageGridTypeTemporary + "\n" + Localizer.productReplacementPageGridTypeGenerateLabels
                    : Localizer.productReplacementPageGridTypeTemporary + "\n" + Localizer.productReplacementPageGridTypeNoLabels,
            init: (cell: CellModel<ProductReplacementItem>) => this.combineRows(cell),
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridProductGroupThreeLevelLanguageItemName,
            minWidth: "15rem",
            maxWidth: "15rem",
            render: (cell: CellModel<ProductReplacementItem>) => this.renderGroupCell(cell)
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridMediqNumberLanguageItemName,
            sorting: true,
            accessor: nameof.full<ProductReplacementItem>(o => o.product!.code),
            minWidth: "7.5rem",
            maxWidth: "7.5rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            name: nameof.full<ProductReplacementItem>(o => o.product!.name),
            header: Localizer.productPanelGridNameLanguageItemName,
            init: (cell: CellModel<ProductReplacementItem>) => this.initProductNameCell(cell),
            accessor: (model: ProductReplacementItem) => Product.getFullName(model.product!),
            sorting: true,
            stretch: true,
            minWidth: "25rem",
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridManufacturerCodeLanguageItemName,
            accessor: nameof.full<ProductReplacementItem>(o => o.product!.manufactureCode),
            minWidth: "10rem",
            maxWidth: "10rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridWholesalePackageQuantityLanguageItemName,
            title: Localizer.productPanelGridWholesalePackageQuantityTitleLanguageItemName,
            accessor: nameof.full<ProductReplacementItem>(o => o.product!.wholesalePackageQuantity),
            textAlign: TextAlign.Center,
            minWidth: "4rem",
            maxWidth: "4rem",
        } as ColumnDefinition,
        {
            header: Localizer.productReplacementPageGridCreatedLanguageItemName,
            sorting: true,
            accessor: nameof.full<ProductReplacementItem>(o => o.owner.createdAt),
            minWidth: "6rem",
            textAlign: TextAlign.Center,
            format: "D",
            init: (cell: CellModel<ProductReplacementItem>) => this.combineRows(cell)
        } as ColumnDefinition,
        {
            header: Localizer.genericActionsLanguageItemName,
            minWidth: 100,
            removable: false,
            init: (cell) => this.initReplacementOperations(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.genericSaveLanguageItemName,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: (cell, action) => this.processReplacementAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "cancel",
                    title: Localizer.genericCancelLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Grey,
                    callback: (cell, action) => this.processReplacementAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "switch",
                    title: Localizer.productReplacementPageGridSwitchTitleLanguageItemName,
                    icon: "fad fa-random",
                    type: ActionType.Blue,
                    confirm: Localizer.productReplacementPageGridSwitchConfirmationLanguageItemName,
                    callback: (cell, action) => this.processReplacementAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "delete",
                    title: Localizer.genericCancelLanguageItemName,
                    icon: "far trash",
                    type: ActionType.Delete,
                    callback: (cell, action) => this.processReplacementAsync(cell, action)
                } as ColumnActionDefinition
            ]

        } as ColumnDefinition,
    ];

    private combineRows(cell: CellModel<ProductReplacementItem>): void {
        const model: ProductReplacementItem = cell.model;
        if (model.isFrom) {
            cell.rowSpan = 2;
        }
    }

    private initProductNameCell(cell: CellModel<ProductReplacementItem>): void {
        const model: ProductReplacementItem = cell.model;
        const deleted: boolean = (model.product!.deleted);
        if (deleted) {
            cell.className = this.cssIf(cell.className, deleted, styles.lineThrough);
        }
    }

    private initRow(row: RowModel<ProductReplacementItem>): void {
        const model: ProductReplacement = row.model.owner;

        const deleted: boolean = (model.deleted);

        row.readonly = deleted;
        row.className = this.cssIf(row.className, deleted, styles.deleted);
    }

    private initOwner(cell: CellModel<ProductReplacementItem>): void {
        const model: ProductReplacement = cell.model.owner;

        this.combineRows(cell);

        const group: boolean = (model.customerGroup != null);
        const customer: boolean = (model.customer != null);

        cell.className = this.cssIf(cell.className, group, styles.group);
        cell.className = this.cssIf(cell.className, customer, styles.customer);
    }

    private async processReplacementAsync(cell: CellModel<ProductReplacementItem>, action: CellAction<ProductReplacement>): Promise<void> {

        const model: ProductReplacement = cell.model.owner;

        if (action.action.name === "save") {

            await this.saveReplacementAsync(model.id, model.type, model.level, model.generateLabels, model.customerGroupId, model.customerId, model.fromProductId, model.toProductId, model.validTo, model.deleteGroupAssortments);

        } else if (action.action.name === "cancel") {

            await cell.row.cancelAsync();

        } else if (action.action.name === "switch") {

            const type: ProductReplacementType = (model.type == ProductReplacementType.Permanent)
                ? ProductReplacementType.Temporary
                : ProductReplacementType.Permanent;

            const generateLabels: boolean = (type == ProductReplacementType.Temporary)
                ? model.generateLabels
                : true;

            await this.saveReplacementAsync(model.id, type, model.level, generateLabels, model.customerGroupId, model.customerId, model.fromProductId, model.toProductId, model.validTo, model.deleteGroupAssortments);

        } else if (action.action.name === "delete") {

            const confirmed: boolean = await ch.confirmAsync(Localizer.productReplacementPageGridReplacementRemoveConfirm);

            if (confirmed) {
                await this.deleteProductReplacementAsync(model.id);
            }

        }

        await cell.row.bindAsync();
    }

    private initReplacementOperations(cell: CellModel<ProductReplacementItem>): void {
        this.combineRows(cell);

        const replacement: ProductReplacement = cell.model.owner;
        const modified: boolean = cell.row.modified;
        const deleted: boolean = replacement.deleted;

        const saveAction: CellAction<ProductReplacementItem> = cell.actions[0];
        const cancelAction: CellAction<ProductReplacementItem> = cell.actions[1];
        const switchAction: CellAction<ProductReplacementItem> = cell.actions[2];
        const deleteAction: CellAction<ProductReplacementItem> = cell.actions[3];

        saveAction.visible = (modified);
        cancelAction.visible = (modified);
        switchAction.visible = (!modified) && (!deleted);
        deleteAction.visible = (!deleted);
    }

    private initValidTo(cell: CellModel<ProductReplacementItem>): void {
        this.combineRows(cell);

        const model: ProductReplacement = cell.model.owner;
        const permanent: boolean = (model.type == ProductReplacementType.Permanent);

        const resetValidToAction: CellAction<ProductReplacementItem> = cell.actions[0];

        resetValidToAction.visible = (!permanent) && (model.validTo != null) && (!model.deleted);

        cell.readonly = permanent;
    }

    private async resetValidToAsync(cell: CellModel<ProductReplacementItem>): Promise<void> {
        const model: ProductReplacement = cell.model.owner;

        model.validTo = null;

        await cell.row.reRenderAsync();
    }

    private async deleteProductReplacementAsync(replacementId: string): Promise<void> {
        const request = new DeleteProductReplacementRequest();
        request.id = replacementId;

        const response: DeleteProductReplacementResponse = await this.postAsync("/api/productManagement/deleteProductReplacement", request);
        
        if (response.contractNamesOfRestoredContractAssortments) {
            await ch.messageBoxAsync(Localizer.productReplacementPageModalContractAssortmentRestored.format(response.contractNamesOfRestoredContractAssortments.join(", ")));
        }

        await ch.alertMessageAsync(Localizer.productReplacementPageGridReplacementRemoved, true, true);
        
        await this.grid.reloadAsync();
    }

    private async fetchAsync(sender: Grid<ProductReplacementItem>, pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null): Promise<IPagedList<ProductReplacementItem>> {
        if (!this.initialized) {
            return [].toPagedList(1, pageSize);
        }

        const request = new ListProductReplacementsRequest();

        request.customerGroupId = ((this.toolbar.replacementLevelFilter == ProductReplacementLevel.Global) || (this.toolbar.replacementLevelFilter == ProductReplacementLevel.Customer)) ? null : this.customerGroupId;
        request.customerId = ((this.toolbar.replacementLevelFilter == ProductReplacementLevel.Global) || (this.toolbar.replacementLevelFilter == ProductReplacementLevel.CustomerGroup)) ? null : this.customerId;
        request.search = this.search;
        request.sortColumnName = sortColumnName;
        request.sortDirection = sortDirection;
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;
        request.showHistory = this.toolbar.showHistory;
        request.showAll = this.toolbar.showAllReplacements;
        request.replacementLevel = this.toolbar.replacementLevelFilter

        const items: IPagedList<ProductReplacement> = await sender.postAsync("/api/productManagement/listProductReplacements", request);

        return ProductReplacementItem.transform(items);
    }

    private async saveReplacementAsync(id: string | null, replacementType: ProductReplacementType, replacementLevel: ProductReplacementLevel, generateLabels: boolean, customerGroupId: string | null, customerId: string | null, fromId: string, toId: string, validTo: Date | null, deleteGroupAssortments: boolean, override: boolean = false): Promise<void> {
        const request = new SaveProductReplacementRequest();
        request.id = id;
        request.customerGroupId = customerGroupId;
        request.customerId = customerId;
        request.fromProductId = fromId;
        request.toProductId = toId;
        request.validTo = validTo;
        request.type = replacementType;
        request.level = replacementLevel;
        request.generateLabels = generateLabels;
        request.assortmentCheckNeeded = true;
        request.override = override;
        request.deleteGroupAssortments = deleteGroupAssortments;

        let response: SaveProductReplacementResponse = await this.postAsync("/api/productManagement/saveProductReplacement", request);

        if (response.replacementHasNoAssortment) {
            const confirm: boolean = await ch.confirmAsync(Localizer.productReplacementPageGridNoAssortmentConfirmation);

            if (confirm) {
                request.assortmentCheckNeeded = false;
                response = await this.postAsync("/api/productManagement/saveProductReplacement", request);
            }
        }

        if (response.existsCyclic) {
            let message: string = (Localizer.productReplacementPageGridCyclicReplacement);

            if (response.existsGlobally?.any()) {
                message = (Localizer.productReplacementPageGridCyclicReplacementGlobally);
            } else if (response.existsOnTheCustomerGroup?.any()) {
                message = (Localizer.productReplacementPageGridCyclicReplacementGroup);
            } else if (response.existsOnTheCustomer?.any()) {
                message = (Localizer.productReplacementPageGridCyclicReplacementCustomer);
            }

            await ch.alertErrorAsync(message, true, true);

            return;
        }

        if (response.existsGlobally?.any()) {
            await ch.alertErrorAsync(Localizer.productReplacementPageGridAlreadyExistsGlobally, true, true);
            return;
        }

        if ((response.existsOnTheCustomerGroup) && (response.existsOnTheCustomerGroup.any())) {
            if (request.level == ProductReplacementLevel.Global) {

                const conflictCount: number = (response.existsOnTheCustomer)
                    ? response.existsOnTheCustomerGroup.length + response.existsOnTheCustomer.length
                    : response.existsOnTheCustomerGroup.length;
                
                let conflictMessage: string = Localizer.productReplacementPageModalConflictMessage.format(conflictCount);

                conflictMessage += SaveProductReplacementResponse.getConflictReplacementsMessage(response);

                const confirmation: IConfirmation = {
                    title: conflictMessage,
                    className: styles.conflictModal,
                } as IConfirmation;

                const confirm: boolean = await ch.confirmAsync(confirmation);

                if (confirm) {
                    await this.saveReplacementAsync(id, replacementType, replacementLevel, generateLabels, customerGroupId, customerId, fromId, toId, validTo, deleteGroupAssortments, true);
                }
            } else {
                await ch.alertErrorAsync(Localizer.productReplacementPageGridAlreadyExistsCustomerGroup, true, true);
            }

            return;
        }

        if ((response.existsOnTheCustomer) && (response.existsOnTheCustomer.any())) {
            if ((request.level == ProductReplacementLevel.Global) || (request.level == ProductReplacementLevel.CustomerGroup)) {
                
                let conflictMessage: string = Localizer.productReplacementPageModalConflictMessage.format(response.existsOnTheCustomer.length);

                conflictMessage += SaveProductReplacementResponse.getConflictReplacementsMessage(response);

                const confirmation: IConfirmation = {
                    title: conflictMessage,
                    className: styles.conflictModal,
                } as IConfirmation;

                const confirm: boolean = await ch.confirmAsync(confirmation);

                if (confirm) {
                    await this.saveReplacementAsync(id, replacementType, replacementLevel, generateLabels, customerGroupId, customerId, fromId, toId, validTo, deleteGroupAssortments, true);
                }
            } else {
                await ch.alertErrorAsync(Localizer.productReplacementPageGridAlreadyExistsCustomer, true, true);
            }

            return;
        }

        await this.reloadAsync();

        const owner: string = (replacementLevel == ProductReplacementLevel.CustomerGroup)
            ? response.productReplacement!.customerGroup!.name
            : (replacementLevel == ProductReplacementLevel.Customer)
                ? response.productReplacement!.customer!.name
                : "";

        const fromCode: string = response.productReplacement!.fromProduct!.code;
        const toCode: string = response.productReplacement!.toProduct!.code;

        const message: string = (!id)
            ? ((replacementLevel == ProductReplacementLevel.Global))
                ? Localizer.productReplacementPageGridNewGlobalReplacementSaved.format(fromCode, toCode)
                : Localizer.productReplacementPageGridNewReplacementSaved.format(fromCode, toCode, owner)
            : Localizer.productReplacementPageGridReplacementUpdated.format(fromCode, toCode);

        await ch.alertMessageAsync(message, true, true);
    }

    private async onToolbarSubmitAsync(toolbar: ProductsToolbarModel): Promise<void> {
        this.state.toolbar = toolbar;
        await this.reloadAsync();
    }

    private async openReplacementModalAsync(): Promise<void> {
        await this._replacementModalRef.current?.openAsync(this.toolbar.replacementLevelFilter, this.toolbar.customerGroup, this.toolbar.customer);
    }

    public async reloadAsync(): Promise<void> {
        await this.grid.reloadAsync();
    }

    public get grid(): GridModel<ProductReplacementItem> {
        return this._replacementsGridRef.current!.model;
    }

    public get toolbar(): ProductsToolbarModel {
        return this.state.toolbar;
    }

    public get customerId(): string | null {
        if (this.toolbar.customer) {
            return this.toolbar.customer.id;
        }

        return null;
    }

    public get customerGroupId(): string | null {
        if (this.toolbar.customerGroup) {
            return this.toolbar.customerGroup.id;
        }

        return null;
    }

    public get initialized(): boolean {
        return this.toolbar.initialized(ProductPanelType.Replacements);
    }

    public get search(): string | null {
        return this.toolbar.search;
    }

    public renderGroupCell(cell: CellModel<ProductReplacementItem>): React.ReactNode {
        const product: Product = cell.model!.product!;

        const mainGroup: string = product.mainGroup ?? "";
        const subGroup: string = product.subGroup ?? "";
        const subSubGroup: string = product.subSubGroup ?? "";

        cell.className = this.css(styles.productGroup, styles.threeProductGroups)

        return (
            <div>

                {
                    (mainGroup) && (
                        <span>{mainGroup}</span>
                    )
                }

                {
                    (subGroup) && (
                        <span>{subGroup}</span>
                    )
                }

                {
                    (subSubGroup) &&
                    (
                        <span>{subSubGroup}</span>
                    )
                }

            </div>
        );
    }

    public render(): React.ReactNode {
        return (
            <div id={this.id} className={this.css(styles.productReplacementsPanel)}>

                <ProductsToolbar type={ProductPanelType.Replacements}
                                 model={this.toolbar}
                                 hideCustomersInSearch={(AittaController.user.isMaster)}
                                 onChange={(toolbar) => this.onToolbarSubmitAsync(toolbar)}
                                 onAddReplacement={() => this.openReplacementModalAsync()}
                />

                <Grid autoToggle pagination optimization responsive
                      id={"replacementsGrid"}
                      className={styles.replacementsGrid}
                      minWidth="auto"
                      ref={this._replacementsGridRef}
                      hovering={GridHoveringType.EditableCell}
                      odd={GridOddType.None}
                      noDataText={Localizer.productReplacementPageGridNoReplacements}
                      headerMinHeight={80}
                      columns={this._columns}
                      borderType={BorderType.DarkSeparators}
                      initRow={(row: RowModel<ProductReplacementItem>) => this.initRow(row)}
                      fetchData={(sender: Grid<ProductReplacementItem>, pageNumber, pageSize, sortColumnName, sortDirection) => this.fetchAsync(sender, pageNumber, pageSize, sortColumnName, sortDirection)}
                />

                <ProductReplacementModal ref={this._replacementModalRef}
                                         onSaveAsync={(replacementType: ProductReplacementType, replacementLevel: ProductReplacementLevel, generateLabels: boolean, customerGroupId: string | null, customerId: string | null, fromId: string, toId: string, expirationDate: Date | null, deleteGroupAssortments: boolean) => this.saveReplacementAsync(null, replacementType, replacementLevel, generateLabels, customerGroupId, customerId, fromId, toId, expirationDate, deleteGroupAssortments)}
                />

            </div>
        )
    }
}