import React from "react";
import {BaseComponent, ch, TextAlign} from "@reapptor-apps/reapptor-react-common";
import {BorderType, CellModel, ColumnDefinition, ColumnType, Grid, GridHoveringType, GridModel, GridOddType} from "@reapptor-apps/reapptor-react-components";
import {FileModel, IPagedList, SortDirection, Utility} from "@reapptor-apps/reapptor-toolkit";
import LabelsToolbarModel, {LabelsPanelType} from "@/pages/LabelsManagement/LabelsToolbar/LabelsToolbarModel";
import LabelsToolbar from "@/pages/LabelsManagement/LabelsToolbar/LabelsToolbar";
import ListProductLabelsRequest from "@/models/server/requests/ListProductLabelsRequest";
import {ProductLabelState, ProductLabelType, ExportEncodingType} from "@/models/Enums";
import ProductLabel from "@/models/server/ProductLabel";
import ExportLabelsToCsvRequest from "@/models/server/requests/ExportLabelsToCsvRequest";
import GetLabelsPrintFileModelRequest from "@/models/server/requests/GetLabelsPrintFileModelRequest";
import SetLabelsPrintedRequest from "@/models/server/requests/SetLabelsPrintedRequest";
import DeleteLabelsRequest from "@/models/server/requests/DeleteLabelsRequest";
import ProductModal from "@/components/ProductModal/ProductModal";
import Product from "@/models/server/Product";
import Localizer from "@/localization/Localizer";
import AittaController from "@/pages/AittaController";

import styles from "./LabelsSelectionPanel.module.scss";
import GetLabelsPrintFileModelResponse from "@/models/server/responses/GetLabelsPrintFileModelResponse";

export interface ILabelsSelectionPanelProps {
    numberOfPrintItemsChange(): Promise<void>
}

interface ILabelsSelectionPanelState {
    toolbar: LabelsToolbarModel;
}

export default class LabelsSelectionPanel extends BaseComponent<ILabelsSelectionPanelProps, ILabelsSelectionPanelState> {

    state: ILabelsSelectionPanelState = {
        toolbar: new LabelsToolbarModel(),
    };

    private readonly _labelsPanelGridRef: React.RefObject<Grid<ProductLabel>> = React.createRef();
    private readonly _labelsToolbarRef: React.RefObject<LabelsToolbar> = React.createRef();
    private readonly _productModalRef: React.RefObject<ProductModal> = React.createRef();

    private readonly _columns: ColumnDefinition[] = [
        {
            name: "customerName",
            header: Localizer.labelSelectionPanelGridCustomerLanguageItemName,
            sorting: true,
            accessor: nameof.full<ProductLabel>(o => o.productAssortment!.customer!.name),
            minWidth: "15rem",
            maxWidth: "15rem",
        } as ColumnDefinition,
        {
            name: "groupName",
            header: Localizer.productPanelGridProductGroupThreeLevelLanguageItemName,
            isDefaultSorting: true,
            sorting: true,
            minWidth: "15rem",
            maxWidth: "15rem",
            render: (cell: CellModel<ProductLabel>) => this.renderGroupCell(cell)
        } as ColumnDefinition,
        {
            name: "productCode",
            header: Localizer.productPanelGridMediqNumberLanguageItemName,
            sorting: true,
            accessor: nameof.full<ProductLabel>(o => o.productCode),
            minWidth: "7,5rem",
            maxWidth: "7,5rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            name: "productName",
            header: Localizer.productPanelGridNameLanguageItemName,
            sorting: true,
            accessor: (model: ProductLabel) => Product.getFullName(model.productAssortment!.product!),
            minWidth: "25rem",
            stretch: true,
            className: styles.hasImage,
            init: (cell: CellModel<ProductLabel>) => this.initNameCell(cell),
            callback: (cell: CellModel<ProductLabel>) => this.onNameClickAsync(cell),
            settings: {
                descriptionAccessor: nameof<ProductLabel>(o => o.comment)
            }
        } as ColumnDefinition,
        {
            name: "manufactureCode",
            header: Localizer.productPanelGridManufacturerCodeLanguageItemName,
            sorting: true,
            accessor: nameof.full<ProductLabel>(o => o.productAssortment!.product!.manufactureCode),
            minWidth: "10rem",
            maxWidth: "10rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridTypeLanguageItemName,
            accessor: nameof.full<ProductLabel>(o => o.type),
            format: nameof<ProductLabelType>(),
            minWidth: "8rem",
            maxWidth: "8rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.labelSelectionPanelGridStateLanguageItemName,
            type: ColumnType.Icon,
            accessor: (model) => ProductLabel.getStateIcon(model),
            minWidth: "5rem",
            maxWidth: "5rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            name: "printDate",
            header: Localizer.labelSelectionPanelGridPrintedDateLanguageItemName,
            sorting: true,
            accessor: nameof.full<ProductLabel>(o => o.printDate),
            format: "D",
            minWidth: "10rem",
            maxWidth: "10rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
    ];

    private initNameCell(cell: CellModel<ProductLabel>): void {
        cell.descriptionReadonly = true;
    }

    private async onNameClickAsync(cell: CellModel<ProductLabel>): Promise<void> {
        const model: ProductLabel = cell.row.model;

        if (this._productModalRef.current) {
            await this._productModalRef.current.openAsync(model.productAssortment?.product ?? model.productAssortmentId, null, null, this.customerId);
        }

        cell.descriptionReadonly = true;
    }

    private async downloadLabelPrintFileModelAsync(pdf: boolean, all: boolean, except: boolean, labelIds: string[]): Promise<GetLabelsPrintFileModelResponse> {
        const request = new GetLabelsPrintFileModelRequest();
        request.productLabelIds = labelIds;
        request.pdf = pdf;
        request.customerId = this.customerId;
        request.all = all;
        request.except = except;

        return await this.postAsync("/api/labelsManagement/getLabelsPrintFileModel", request, false, 60);
    }

    private async printLabelsAsync(all: boolean, except: boolean): Promise<void> {
        const checkedLabels: string[] = this.checkedLabelIds;

        const canPrint: boolean = await this.checkPrintLabelsValidAsync(all, except, checkedLabels.length);

        if (!canPrint) {
            return;
        }

        const response: GetLabelsPrintFileModelResponse = await this.downloadLabelPrintFileModelAsync(false, all, except, checkedLabels);

        const objectUrl: string = Utility.toObjectUrl(response.fileModel!);

        const newWindow: Window | null = window.open(objectUrl);

        newWindow?.print();

        const request = new SetLabelsPrintedRequest();
        request.customerId = this.customerId;
        request.all = all;
        request.except = except;
        request.productLabelIds = checkedLabels;

        await this.postAsync("/api/labelsManagement/setLabelsPrinted", request);

        await this.props.numberOfPrintItemsChange();

        await this.selectProductLabelsAsync([]);

        await this.grid.reloadAsync();
    }

    private async deleteLabelsAsync(all: boolean, except: boolean): Promise<void> {
        const checkedLabels: string[] = this.checkedLabelIds;

        const canDelete: boolean = await this.checkPrintLabelsValidAsync(all, except, checkedLabels.length);

        if (!canDelete) {
            return;
        }

        const confirmed: boolean = await ch.confirmAsync(Localizer.labelSelectionPanelConfirmationAreYouSureYouWantToDelete);

        if (!confirmed) {
            return;
        }

        const request = new DeleteLabelsRequest();
        request.customerId = this.customerId;
        request.all = all;
        request.except = except;
        request.productLabelIds = checkedLabels;

        await this.postAsync("/api/labelsManagement/deleteLabels", request);

        await this.props.numberOfPrintItemsChange();

        await this.selectProductLabelsAsync([]);

        await this.grid.reloadAsync();
    }

    private async downloadPdfAsync(all: boolean, except: boolean): Promise<void> {
        const checkedLabels: string[] = this.checkedLabelIds;

        const canPdf: boolean = await this.checkPrintLabelsValidAsync(all, except, checkedLabels.length);

        if (!canPdf) {
            return;
        }

        const response: GetLabelsPrintFileModelResponse = await this.downloadLabelPrintFileModelAsync(true, all, except, checkedLabels);

        if (response.pdfPrintLimitExceeded) {
            await ch.alertWarningAsync(Localizer.labelSelectionPanelMaximunLabelPdfPrint.format(response.labelsPdfMaxCount));
            return;
        }
        
        ch.download(response.fileModel!);
    }

    private async checkPrintLabelsValidAsync(all: boolean, except: boolean, checkedLabelsCount: number): Promise<boolean> {
        const totalLength: number = (this.grid.items.length);

        if (!all && !except && checkedLabelsCount == 0) {
            await ch.alertWarningAsync(Localizer.labelSelectionPanelWarningNoSelectedLabels, true, true);
            return false;
        }

        if (!all && except && (totalLength - checkedLabelsCount) <= 0) {
            await ch.alertWarningAsync(Localizer.labelSelectionPanelWarningNoLabelsToProcess, true, true);
            return false;
        }

        if (all && totalLength == 0) {
            await ch.alertWarningAsync(Localizer.labelSelectionPanelWarningNoOpenLabels, true, true);
            return false;
        }

        return true;
    }

    private async downloadCsvAsync(encodingType: ExportEncodingType): Promise<void> {
        const request = new ExportLabelsToCsvRequest();
        request.encodingType = encodingType;
        request.labelIds = this.grid!.items.map(item => item.id);

        const file: FileModel = await this.postAsync("/api/LabelsManagement/exportLabelsToCsv", request);

        ch.download(file);
    }

    private async onToolbarSubmitAsync(toolbar: LabelsToolbarModel): Promise<void> {
        await this.setState({toolbar});

        await this.reloadAsync();
    }

    private async fetchAsync(sender: Grid<ProductLabel>, pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null): Promise<IPagedList<ProductLabel>> {
        if (!LabelsToolbarModel.initialized(this.toolbar, LabelsPanelType.LabelsSelection)) {
            return [].toPagedList(1, pageSize);
        }

        const request = new ListProductLabelsRequest();

        request.customerGroupId = this.customerGroupId;
        request.customerId = this.customerId;
        request.customerHandler = this.toolbar.customerHandler;
        request.productLabelState = (this.toolbar.showPrintedOnly) ? ProductLabelState.Printed : ProductLabelState.Open;
        request.showPrintedOnly = this.toolbar.showPrintedOnly;
        request.printedDate = this.toolbar.printedDate;
        request.search = this.search;
        request.sortColumnName = sortColumnName;
        request.sortDirection = sortDirection;
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;

        return await sender.postAsync("/api/labelsManagement/listProductLabels", request);
    }

    private async selectProductLabelsAsync(checkedProducts: ProductLabel[]): Promise<void> {
        await this._labelsToolbarRef.current?.selectProductLabelsAsync(checkedProducts);
    }

    private async onCheckAsync(sender: GridModel<ProductLabel>): Promise<void> {
        const checkedProducts: ProductLabel[] = sender.checkedItems;

        await this.selectProductLabelsAsync(checkedProducts);
    }

    public async reloadAsync(): Promise<void> {
        await this.grid.reloadAsync();
    }

    public get checkedLabelIds(): string[] {
        const labels: ProductLabel[] = this.grid.checkedItems;

        return labels.map(item => item.id);
    }

    public get grid(): GridModel<ProductLabel> {
        return this._labelsPanelGridRef.current!.model;
    }

    public get customerId(): string | null {
        return this.toolbar.customer?.id || null;
    }

    public get customerGroupId(): string | null {
        return this.toolbar.customerGroup?.id || null;
    }

    public get search(): string | null {
        return this.toolbar.search;
    }

    public get toolbar(): LabelsToolbarModel {
        return this.state.toolbar;
    }

    public renderGroupCell(cell: CellModel<ProductLabel>): React.ReactNode {
        const product: Product = cell.model!.productAssortment!.product!;

        const mainGroup: string = product.mainGroup ?? "";
        const subGroup: string = product.subGroup ?? "";
        const subSubGroup: string = product.subSubGroup ?? "";

        cell.className = this.css(styles.productGroup, styles.threeProductGroups)

        return (
            <div>

                {
                    (mainGroup) && (
                        <span>{mainGroup}</span>
                    )
                }

                {
                    (subGroup) && (
                        <span>{subGroup}</span>
                    )
                }

                {
                    (subSubGroup) &&
                    (
                        <span>{subSubGroup}</span>
                    )
                }

            </div>
        );
    }

    public render(): React.ReactNode {
        return (
            <div id={this.id} className={this.css(styles.labelsSelectionPanel)}>

                <LabelsToolbar ref={this._labelsToolbarRef}
                               model={this.toolbar}
                               type={LabelsPanelType.LabelsSelection}
                               hideCustomersInSearch={(AittaController.user.isMaster)}
                               downloadCsv={(encodingType: ExportEncodingType) => this.downloadCsvAsync(encodingType)}
                               printLabels={(all: boolean, except: boolean) => this.printLabelsAsync(all, except)}
                               deleteLabels={(all: boolean, except: boolean) => this.deleteLabelsAsync(all, except)}
                               downloadPdf={(all: boolean, except: boolean) => this.downloadPdfAsync(all, except)}
                               onChange={(sender: LabelsToolbar, toolbar: LabelsToolbarModel) => this.onToolbarSubmitAsync(toolbar)}/>

                <Grid optimization responsive checkable
                      id={"labelsSelectionPanelGrid"}
                      ref={this._labelsPanelGridRef}
                      pagination={{pageSize: 100, dataPerPageVariants: [25, 50, 100, 200, 300, 400, 500]}}
                      minWidth="auto"
                      hovering={GridHoveringType.Row}
                      className={styles.productsPanelGrid}
                      headerMinHeight={80}
                      odd={GridOddType.None}
                      borderType={BorderType.NoSeparators}
                      columns={this._columns}
                      noDataText={Localizer.genericNoData}
                      onCheck={(sender: GridModel<ProductLabel>) => this.onCheckAsync(sender)}
                      fetchData={(sender: Grid<ProductLabel>, pageNumber, pageSize, sortColumnName, sortDirection) => this.fetchAsync(sender, pageNumber, pageSize, sortColumnName, sortDirection)}
                />

                <ProductModal id={"productModal"}
                              ref={this._productModalRef}
                />

            </div>
        )
    }
}