import React from "react";
import {ActionType, BaseComponent, ch, TextAlign, UserInteractionDataStorage} from "@reapptor-apps/reapptor-react-common";
import {ExportEncodingType, ProductAssortmentType, ProductOrderFilter} from "@/models/Enums";
import OrdersToolbar from "@/pages/OrderManagement/OrdersToolbar/OrdersToolbar";
import OrdersToolbarModel, {OrderPanelType} from "@/pages/OrderManagement/OrdersToolbar/OrdersToolbarModel";
import OrderProduct from "@/models/server/OrderProduct";
import {BorderType, CellAction, CellModel, ColumnActionDefinition, ColumnDefinition, ColumnType, Grid, GridHoveringType, GridModel, GridOddType, RowModel} from "@reapptor-apps/reapptor-react-components";
import {FileModel, SortDirection, Utility} from "@reapptor-apps/reapptor-toolkit";
import ListOrderProductsRequest from "@/models/server/requests/ListOrderProductsRequest";
import AittaConstants from "@/helpers/AittaConstants";
import OrderProductSelection from "@/models/server/OrderProductSelection";
import Order from "@/models/server/Order";
import ProductModal from "@/components/ProductModal/ProductModal";
import Product from "@/models/server/Product";
import User from "@/models/server/User";
import ExportOrderProductsToCsvRequest from "@/models/server/requests/ExportOrderProductsToCsvRequest";
import Customer from "@/models/server/Customer";
import GoogleAnalyticsHelper from "@/helpers/GoogleAnalyticsHelper";
import AittaController from "@/pages/AittaController";
import Localizer from "@/localization/Localizer";

import styles from "./ProductsPanel.module.scss";

export interface IProductsPanelProps {
    type: OrderPanelType;
    onChange?(sender: ProductsPanel, order: Order, product: OrderProductSelection, expressOrder: boolean): Promise<void>;
    onClear?(sender: ProductsPanel, order: Order): Promise<void>;
    onSubmit?(sender: ProductsPanel, customerId: string, products: OrderProduct[], expressOrder: boolean): Promise<boolean>;
    downloadOrderToCsv?(sender: ProductsPanel, order: Order, encodingType: ExportEncodingType): Promise<void>;
    send?(sender: ProductsPanel, order: Order, close: boolean): Promise<void>;
}

interface IProductsPanelState {
    toolbar: OrdersToolbarModel;
    products: OrderProduct[];
}

export default class ProductsPanel extends BaseComponent<IProductsPanelProps, IProductsPanelState> {

    state: IProductsPanelState = {
        toolbar: this.initializeToolbar(),
        products: [],
    };

    private readonly _toolbarRef: React.RefObject<OrdersToolbar> = React.createRef();
    private readonly _productsPanelGridRef: React.RefObject<Grid<OrderProduct>> = React.createRef();
    private readonly _productModalRef: React.RefObject<ProductModal> = React.createRef();
    private static readonly _broadcastChannelId: string = Utility.newGuid();
    private _broadcastChannel: BroadcastChannel | null = null;

    private readonly _columns: ColumnDefinition[] = [
        {
            header: "fal fa-star",
            accessor: (model: OrderProduct) => model.productAssortment!.favorite ? "fas fa-star" : "fal fa-star",
            type: ColumnType.Icon,
            minWidth: "46px",
            textAlign: TextAlign.Center,
            className: styles.favorite,
            callback: (cell: CellModel<OrderProduct>) => this.setFavoriteAsync(cell),
        },
        {
            header: Localizer.productPanelGridAittaLanguageItemName,
            minWidth: "5rem",
            textAlign: TextAlign.Center,
            init: (cell) => this.initSelectionColumn(cell),
            actions: [
                {
                    title: "AITTA",
                    type: ActionType.Light,
                } as ColumnActionDefinition
            ]
        },
        {
            name: "groupName",
            header: Localizer.productPanelGridProductGroupThreeLevelLanguageItemName,
            sorting: true,
            isDefaultSorting: true,
            minWidth: "15rem",
            maxWidth: "15rem",
            render: (cell: CellModel<OrderProduct>) => this.renderGroupCell(cell)
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridMediqNumberLanguageItemName,
            sorting: true,
            accessor: nameof.full<OrderProduct>(o => o.product!.code),
            minWidth: "7.5rem",
            maxWidth: "7.5rem",
            textAlign: TextAlign.Center,
            init: (cell: CellModel<OrderProduct>) => this.initCodeCell(cell),
            settings: {
                descriptionIcon: "far fa-exchange-alt",
                descriptionAccessor: () => "",
            },
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridNameLanguageItemName,
            sorting: true,
            isDefaultSorting: true,
            name: nameof.full<OrderProduct>(o => o.product!.name),
            accessor: (model: OrderProduct) => Product.getFullName(model.product!),
            minWidth: "25rem",
            stretch: true,
            className: styles.hasImage,
            init: (cell: CellModel<OrderProduct>) => this.initName(cell),
            callback: (cell: CellModel<OrderProduct>) => this.onNameClickAsync(cell),
            settings: {
                descriptionAccessor: nameof.full<OrderProduct>(o => o.productAssortment!.note),
            },
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridManufacturerCodeLanguageItemName,
            sorting: true,
            accessor: nameof.full<OrderProduct>(o => o.product!.manufactureCode),
            minWidth: "10rem",
            maxWidth: "10rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridWholesalePackageQuantityLanguageItemName,
            title: Localizer.productPanelGridWholesalePackageQuantityTitleLanguageItemName,
            accessor: nameof.full<OrderProduct>(o => o.product!.wholesalePackageQuantity),
            textAlign: TextAlign.Center,
            minWidth: "4rem",
            maxWidth: "4rem",
        } as ColumnDefinition,
        {
            group: Localizer.productPanelGridQuantityLanguageItemName,
            header: Localizer.productPanelGridSubmittedLanguageItemName,
            title: Localizer.productPanelGridSubmittedColumnTitleLanguageItemName,
            init: (cell: CellModel<OrderProduct>) => this.initSubmittedColumn(cell),
            accessor: (model: OrderProduct) => this.getSubmittedValue(model),
            visible: () => this.submittedColumnVisible,
            textAlign: TextAlign.Center,
            minWidth: "4rem",
            maxWidth: "4rem",
        } as ColumnDefinition,
        {
            group: Localizer.productPanelGridQuantityLanguageItemName,
            header: Localizer.productPanelGridAssumedLanguageItemName,
            sorting: true,
            accessor: (model: OrderProduct) => (model.productAssortment!.orderQuantity > 0) ? model.productAssortment!.orderQuantity : "-",
            minWidth: "4rem",
            maxWidth: "4rem",
            textAlign: (this.type == OrderPanelType.ProductsSelection) ? TextAlign.Left : TextAlign.Center,
            visible: () => this.assumedColumnVisible,
            init: (cell: CellModel<OrderProduct>) => this.initAssumedOrderQuantityColumn(cell),
            actions: [
                {
                    name: "set",
                    title: Localizer.productPanelGridOrderAssumedLanguageItemName,
                    type: ActionType.Create,
                    icon: "far fa-arrow-alt-right",
                    callback: (cell: CellModel<OrderProduct>) => this.setAssumedOrderQuantityAsync(cell),
                } as ColumnActionDefinition
            ]
        } as ColumnDefinition,
        {
            name: "orderQuantity",
            group: Localizer.productPanelGridQuantityLanguageItemName,
            header: Localizer.productPanelGridOrderQuantityLanguageItemName,
            sorting: true,
            accessor: nameof.full<OrderProduct>(o => o.quantity),
            type: ColumnType.Number,
            minWidth: "7rem",
            maxWidth: "7rem",
            className: styles.quantity,
            textAlign: TextAlign.Center,
            settings: {
                min: 0,
                max: AittaConstants.maxOrderProductQuantity,
                step: 1,
                infoAccessor: (model: OrderProduct) => OrderProduct.getQuantityDifference(model),
                infoHideZero: true,
                infoHideEqual: true
            },
            init: (cell: CellModel<OrderProduct>) => this.initOrderQuantityColumn(cell),
            callback: (cell: CellModel<OrderProduct>) => this.changeOrderQuantityAsync(cell),
            actions: [
                {
                    name: "minus",
                    title: Localizer.productPanelGridDecreaseProductQuantityLanguageItemName,
                    type: ActionType.Blue,
                    icon: "fas fa-minus-circle",
                    callback: (cell: CellModel<OrderProduct>) => this.setOrderQuantityAsync(cell, false),
                } as ColumnActionDefinition,
                {
                    name: "plus",
                    title: Localizer.productPanelGridIncreaseProductQuantityLanguageItemName,
                    type: ActionType.Blue,
                    icon: "fas fa-plus-circle",
                    callback: (cell: CellModel<OrderProduct>) => this.setOrderQuantityAsync(cell, true),
                } as ColumnActionDefinition,
            ]
        } as ColumnDefinition,
        {
            name: "delete",
            visible: () => this.actionsColumnVisible,
            minWidth: "3rem",
            maxWidth: "3rem",
            actions: [
                {
                    name: "delete",
                    title: Localizer.productPanelGridDeleteProductOrderLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    callback: (cell: CellModel<OrderProduct>) => this.setOrderQuantityAsync(cell, false, true),
                } as ColumnActionDefinition,
            ]
        } as ColumnDefinition
    ];

    private initCodeCell(cell: CellModel<OrderProduct>): void {
        const model: OrderProduct = cell.model;

        const replacementProductId: string | null = OrderProduct.getReplacementId(model)

        const canShowReplacementInfo: boolean = (!AittaController.user.isMaster);
        const hasFromReplacement: boolean = (canShowReplacementInfo) && (replacementProductId != null);
        const hasToReplacement: boolean = (canShowReplacementInfo) && (model.toProductReplacementIds != null) && (model.toProductReplacementIds.length > 0);
        const hasReplacement: boolean = (canShowReplacementInfo) && ((hasFromReplacement) || (hasToReplacement));
        
        cell.className = this.cssIf(cell.className, hasReplacement, styles.hasReplacement);
        cell.className = this.cssIf(cell.className, !hasReplacement, styles.noReplacement);
        cell.className = this.cssIf(cell.className, hasToReplacement, styles.hasToReplacement);
    }

    private async fetchAsync(sender: Grid<OrderProduct>, sortColumnName: string | null, sortDirection: SortDirection | null): Promise<OrderProduct[]> {
        if (!this.initialized) {
            return [];
        }

        const request = new ListOrderProductsRequest();
        request.search = this.search;
        request.sortColumnName = sortColumnName;
        request.sortDirection = sortDirection;
        request.customerId = this.customerId!;
        request.customerGroupId = this.customerGroupId!;
        request.productOrderFilter = this.productOrderFilter;

        request.expressOrder = (this.type == OrderPanelType.ExpressOrder)
            ? true
            : (this.type == OrderPanelType.Order)
                ? false
                : null;

        const products: OrderProduct[] = await sender.postAsync("/api/orderManagement/listOrderProducts", request);
        
        await this.syncSelectedProductsAsync(products);

        return products;
    }

    private initializeToolbar(): OrdersToolbarModel {
        let toolbar: OrdersToolbarModel | null = UserInteractionDataStorage.get(this.toolbarCacheKey);

        if (toolbar == null) {
            toolbar = new OrdersToolbarModel();

            switch (this.type) {
                case OrderPanelType.Order:
                case OrderPanelType.ExpressOrder:
                    toolbar.productOrderFilter = ProductOrderFilter.Order;
                    break;

                case OrderPanelType.ProductsSelection:
                    toolbar.productOrderFilter = ProductOrderFilter.CustomerSelection;
                    break;
            }
        }
        
        toolbar.customer = null;
        toolbar.dataInitialized = false;

        return toolbar;
    }

    private initRow(row: RowModel<OrderProduct>): void {
        const model: OrderProduct = row.model;

        const valuable: boolean = (model.quantity > 0) && ((this.type == OrderPanelType.ProductsSelection) || (this.productOrderFilter != ProductOrderFilter.Order));
        const removed: boolean = (model.quantity == 0) && (model.prevQuantity > 0);

        row.className = this.cssIf(row.className, valuable, styles.valuable);
        row.className = this.cssIf(row.className, removed, styles.removed);
    }

    private initAssumedOrderQuantityColumn(cell: CellModel<OrderProduct>): void {
        const model: OrderProduct = cell.model;

        const setAction: CellAction<OrderProduct> = cell.actions[0];

        setAction.visible = (model.prevQuantity == 0) && (model.quantity == 0) && (model.productAssortment!.orderQuantity > 0);
    }

    private initOrderQuantityColumn(cell: CellModel<OrderProduct>): void {
        const model: OrderProduct = cell.model;
        
        const minus: CellAction<OrderProduct> = cell.actions[0];
        const plus: CellAction<OrderProduct> = cell.actions[1];

        minus.disabled = (model.quantity <= 0);
        plus.disabled = (model.quantity >= AittaConstants.maxOrderProductQuantity);

        cell.className = this.cssIf(cell.className, cell.modified, styles.modified);
    }

    private initSelectionColumn(cell: CellModel<OrderProduct>): void {
        const model: OrderProduct = cell.model;

        const selected: boolean = (model.productAssortment!.type == ProductAssortmentType.Customer);

        cell.className = styles.aitta;
        cell.className = this.cssIf(cell.className, selected, styles.selected);
        cell.className = this.cssIf(cell.className, !selected, styles.unselected);
    }

    private initName(cell: CellModel<OrderProduct>): void {
        const model: OrderProduct = cell.row.model;

        cell.descriptionAction!.visible = (!!model.productAssortment?.note);
        cell.descriptionReadonly = true;
    }

    private initSubmittedColumn(cell: CellModel<OrderProduct>): void {
        const model: OrderProduct = cell.row.model;

        cell.title = (this.type == OrderPanelType.ProductsSelection)
            ? ((model.submittedQuantity) || (model.orderQuantity) || (model.expressOrderQuantity))
                ? Localizer.productPanelGridSubmittedExtendedTitle.format((model.orderQuantity ?? 0), (model.expressOrderQuantity ?? 0), model.submittedQuantity)
                : ""
            : (model.submittedQuantity)
                ? Localizer.productPanelGridSubmittedTitle.format(model.submittedQuantity)
                : "";
    }

    private getSubmittedValue(model: OrderProduct): string {
        return (this.type == OrderPanelType.ProductsSelection)
            ? ((model.submittedQuantity) || (model.orderQuantity) || (model.expressOrderQuantity))
                ? "{0}/{1}/{2}".format((model.orderQuantity ?? 0), (model.expressOrderQuantity ?? 0), model.submittedQuantity)
                : ""
            : (model.submittedQuantity)
                ? "{0}".format(model.submittedQuantity)
                : "";
    }
    
    private getSelectedProductsKey(): string {
        return `OpenOrder:${this.customerId}`;
    }
    
    private getSelectedProducts(): OrderProduct[] {
        const isProductSelection: boolean = (this.type == OrderPanelType.ProductsSelection);
        const isOrder: boolean = (this.type == OrderPanelType.Order) || (this.type == OrderPanelType.ExpressOrder);

        return (isProductSelection)
            ? UserInteractionDataStorage.get(this.getSelectedProductsKey(), [])
            : (isOrder)
                ? this.grid.data.where(item => item.quantity > 0)
                : [];
    }
    
    private setSelectedProduct(product: OrderProduct): void {
        const selectedProducts: OrderProduct[] = this.getSelectedProducts();
        let selectedProduct: OrderProduct | null = selectedProducts.firstOrDefault(item => item.productAssortmentId == product?.productAssortmentId);
        if (selectedProduct != null) {
            if (product.quantity > 0) {
                selectedProduct.quantity = product.quantity;
            } else {
                selectedProducts.remove(selectedProduct);
            }
        } else if (product.quantity > 0) {
            selectedProducts.push(product);
        }
        
        UserInteractionDataStorage.set(this.getSelectedProductsKey(), selectedProducts);
    }
    
    private async onClearAsync(): Promise<void> {
        if (this.type == OrderPanelType.ProductsSelection) {
            await this.clearSelectedProductsAsync();
        } else if ((this.props.onClear) && (this.order) && ((this.type == OrderPanelType.ExpressOrder) || (this.type == OrderPanelType.Order))) {
            await this.props.onClear(this, this.order);
        }

        const productsToClear: OrderProduct[] =  this.grid.data.where(item => item.quantity > 0);

        productsToClear.map(item => item.quantity = 0);
        
        GoogleAnalyticsHelper.removeFromCart(this.order, productsToClear)
        
        await this.grid.reRenderAsync();
    }
    
    private async clearSelectedProductsAsync(): Promise<void> {
        
        if (this.type == OrderPanelType.ProductsSelection) {
            UserInteractionDataStorage.set(this.getSelectedProductsKey(), []);

            await this.setProductsToToolbarAsync();
        }
    }
    
    private async syncSelectedProductsAsync(products: OrderProduct[]): Promise<void> {
        const isProductSelection: boolean = (this.type == OrderPanelType.ProductsSelection);
        const isOrder: boolean = (this.type == OrderPanelType.Order) || (this.type == OrderPanelType.ExpressOrder);

        if ((isProductSelection) || (isOrder)) {

            if (isProductSelection) {
                const selectedProducts: OrderProduct[] = this.getSelectedProducts();

                const length: number = selectedProducts.length;
                for (let i: number = 0; i < length; i++) {
                    const selectedProduct: OrderProduct = selectedProducts[i];
                    const product: OrderProduct | null = products.firstOrDefault(item => item.productAssortmentId == selectedProduct.productAssortmentId);
                    if (product) {
                        product.quantity = selectedProduct.quantity;
                    }
                }

                await this.setProductsToToolbarAsync(selectedProducts);
            }

            if (isOrder) {
                await this.setProductsToToolbarAsync(products);
            }
        }
    }    

    private async setFavoriteAsync(cell: CellModel<OrderProduct>): Promise<void> {
        const model: OrderProduct = cell.row.model;

        model.productAssortment!.favorite = !model.productAssortment!.favorite;

        AittaController.setFavorite(model.productAssortmentId!, model.productAssortment!.favorite);

        await cell.bindAsync();
    }

    private async setAssumedOrderQuantityAsync(cell: CellModel<OrderProduct>): Promise<void> {
        const model: OrderProduct = cell.row.model;

        model.quantity = model.productAssortment!.orderQuantity;

        await cell.row.reRenderAsync();

        await this.onOrderQuantityChangeAsync(model);

        GoogleAnalyticsHelper.onCartChange(this.order, model);
    }

    private async setOrderQuantityAsync(cell: CellModel<OrderProduct>, plus: boolean, clear: boolean = false): Promise<void> {
        const model: OrderProduct = cell.row.model;
        
        model.quantity = (clear)
            ? 0
            : (plus)
                ? model.quantity + 1
                : model.quantity - 1;
        
        await cell.row.reRenderAsync();

        await this.onOrderQuantityChangeAsync(model);

        GoogleAnalyticsHelper.onCartChange(this.order, model);
    }

    private async changeOrderQuantityAsync(cell: CellModel<OrderProduct>): Promise<void> {
        const model: OrderProduct = cell.model;

        await cell.row.reRenderAsync();

        await this.onOrderQuantityChangeAsync(model);
    }

    private async onNameClickAsync(cell: CellModel<OrderProduct>): Promise<void> {
        const model: OrderProduct = cell.row.model;

        if (this._productModalRef.current) {
            const productOrId: Product | string = model.product ?? model.productAssortmentId;
            const replacementProductId: string | null = OrderProduct.getReplacementId(model);
            
            await this._productModalRef.current.openAsync(productOrId, replacementProductId, model.replacementProductAssortmentId, this.customerId, model.toProductReplacementIds);
        }
    }

    private async setProductsToToolbarAsync(products: OrderProduct[] | null = null): Promise<void> {
        const toolbar: OrdersToolbar | null = this._toolbarRef.current;
        const isProductSelectionOrOrder: boolean = (this.type == OrderPanelType.ProductsSelection) || (this.type == OrderPanelType.Order) || (this.type == OrderPanelType.ExpressOrder);
        
        if ((toolbar) && (isProductSelectionOrOrder)) {
            
            const selectedProducts: OrderProduct[] = products ?? this.getSelectedProducts();

            await toolbar.setProductsAsync(selectedProducts);
        }
    }

    private async onOrderQuantityChangeAsync(product: OrderProduct): Promise<void> {

        if (this.type == OrderPanelType.ProductsSelection) {
            this.setSelectedProduct(product);
            const message: string = `modified:${ProductsPanel._broadcastChannelId}`;
            this._broadcastChannel?.postMessage(message);
        }

        await this.setProductsToToolbarAsync();

        if ((this.props.onChange) && (this.order)) {
            const expressOrder: boolean = (this.type == OrderPanelType.ExpressOrder);
            await this.props.onChange(this, this.order, product, expressOrder);
        }
    }

    private async onToolbarSubmitAsync(): Promise<void> {        
        UserInteractionDataStorage.set(this.toolbarCacheKey, this.toolbar);
        
        await this.reloadAsync();
    }

    private async submitOrderAsync(products: OrderProduct[], expressOrder: boolean): Promise<void> {
        
        if ((this.props.onSubmit) && (this.customerId)) {
            const send: boolean = await this.props.onSubmit(this, this.customerId, products, expressOrder);
            
            if (send) {
                await this.clearSelectedProductsAsync();
            }
        }
    }

    private async assumeAsync(): Promise<void> {
        if (this.customerId) {
            const products: OrderProduct[] = this.grid.data;

            let count: number = 0;
            for (let i: number = 0; i < products.length; i++) {
                const product: OrderProduct = products[i];
                if ((product.quantity == 0) && (product.quantity == product.prevQuantity) && (product.productAssortment) && (product.productAssortment.orderQuantity > 0)) {
                    product.quantity = product.productAssortment.orderQuantity;
                    count++;
                    this.setSelectedProduct(product);
                }
            }

            if (count > 0) {
                await this.grid.reRenderAsync();
                await this.setProductsToToolbarAsync();
            }
            
            GoogleAnalyticsHelper.addToCart(this.order, products);
        }
    }

    private async downloadOrderCsvAsync(order: Order, encodingType: ExportEncodingType): Promise<void> {
        if (this.props.downloadOrderToCsv) {
            await this.props.downloadOrderToCsv(this, order, encodingType);
        }
    }

    private async downloadProductsCsvAsync(encodingType: ExportEncodingType): Promise<void> {
        const customer: Customer | null = this.toolbar.customer;

        const sortColumnName: string | null = (this.grid.sortColumn)
            ? this.grid.sortColumn.name
                ? this.grid.sortColumn.name
                : (typeof this.grid.sortColumn.accessor === "string")
                    ? this.grid.sortColumn.accessor
                    : null
            : null;
        
        if (customer) {
            const request = new ExportOrderProductsToCsvRequest();
            request.customerGroupId = customer.customerGroupId;
            request.customerId =customer.id;
            request.productOrderFilter = this.toolbar.productOrderFilter;
            request.sortColumnName = sortColumnName;
            request.sortDirection = this.grid.sortDirection;
            request.encodingType = encodingType;
            request.search = this.toolbar.search;

            const file: FileModel = await this.postAsync("/api/orderManagement/ExportOrderProductsToCsv", request)

            ch.download(file);
        }
    }

    private async sendOrderAsync(order: Order, close: boolean): Promise<void> {
        if (this.props.send) {
            await this.props.send(this, order, close);
        }
    }
    
    private async onBroadcastMessageAsync(event: MessageEvent): Promise<void> {
        const message: string = event.data;
        if (message.startsWith("modified:")) {
            const self: boolean = (message == `modified:${ProductsPanel._broadcastChannelId}`);
            if (!self) {
                UserInteractionDataStorage.reload();
                if ((this.type == OrderPanelType.ProductsSelection) && (this._productsPanelGridRef.current?.hasData)) {
                    await this.grid.reloadAsync();
                }
            }
        }
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        if (this._broadcastChannel == null) {
            this._broadcastChannel = new BroadcastChannel(`${ch.getUserId()}:OpenOrder`);
            this._broadcastChannel.onmessage = (event: MessageEvent) => this.onBroadcastMessageAsync(event);
        }
    }

    public async reloadAsync(): Promise<void> {
        await this.grid.reloadAsync();

        if (this._toolbarRef.current) {
            await this._toolbarRef.current.reloadAsync()
        }
    }

    public get grid(): GridModel<OrderProduct> {
        return this._productsPanelGridRef.current!.model;
    }

    public get type(): OrderPanelType {
        return this.props.type;
    }

    public get toolbar(): OrdersToolbarModel {
        return this.state.toolbar;
    }
    
    public get toolbarCacheKey(): string {
        return `toolbar.${this.type}`;
    }

    public get productOrderFilter(): ProductOrderFilter {
        return OrdersToolbarModel.getProductOrderFilter(this.toolbar, this.type);
    }

    public get submittedColumnVisible(): boolean {
        const user: User = ch.getUser();
        return (
            (user.isMediq) &&
            (
                (this.type != OrderPanelType.ProductsSelection) ||
                (this.productOrderFilter != ProductOrderFilter.CustomerGroupSelection)
            )
        );
    }

    public get assumedColumnVisible(): boolean {
        return (
            (this.type == OrderPanelType.Order) ||
            (
                (this.type == OrderPanelType.ProductsSelection) &&
                (this.productOrderFilter != ProductOrderFilter.CustomerGroupSelection)
            )
        );
    }

    public get actionsColumnVisible(): boolean {
        return ((this.type == OrderPanelType.Order) || (this.type == OrderPanelType.ExpressOrder));
    }

    public get customerId(): string | null {
        return this.toolbar.customer?.id || null;
    }

    public get order(): Order | null {
        return this._toolbarRef.current?.order || null;
    }

    public get customerGroupId(): string | null {
        return this.toolbar.customer?.customerGroupId || null;
    }
    
    public get initialized(): boolean {
        return OrdersToolbarModel.initialized(this.toolbar);
    }

    public get search(): string | null {
        return this.toolbar.search;
    }

    public renderGroupCell(cell: CellModel<OrderProduct>): React.ReactNode {
        const product: Product = cell.model!.product!;

        const mainGroup: string = product.mainGroup ?? "";
        const subGroup: string = product.subGroup ?? "";
        const subSubGroup: string = product.subSubGroup ?? "";

        cell.className = this.css(styles.productGroup, styles.threeProductGroups)

        return (
            <div>

                {
                    (mainGroup) && (
                        <span>{mainGroup}</span>
                    )
                }

                {
                    (subGroup) && (
                        <span>{subGroup}</span>
                    )
                }

                {
                    (subSubGroup) &&
                    (
                        <span>{subSubGroup}</span>
                    )
                }

            </div>
        );
    }
    
    public render(): React.ReactNode {
        return (
            <div id={this.id} className={this.css(styles.productsPanel)}>

                <OrdersToolbar ref={this._toolbarRef}
                               className={styles.stickyToolbar}
                               type={this.type}
                               model={this.toolbar}
                               hideCustomersInSearch={(AittaController.user.isMaster)}
                               onChange={() => this.onToolbarSubmitAsync()}
                               onAssume={() => this.assumeAsync()}
                               onClear={() => this.onClearAsync()}
                               onSubmit={(sender, products, express) => this.submitOrderAsync(products, express)}
                               send={(sender, order, close: boolean) => this.sendOrderAsync(order, close)}
                               downloadOrderCsv={(sender: OrdersToolbar, order: Order, encodingType: ExportEncodingType) => this.downloadOrderCsvAsync(order, encodingType)}
                               downloadProductsCsv={(sender: OrdersToolbar, encodingType: ExportEncodingType) => this.downloadProductsCsvAsync(encodingType)}
                />

                <Grid optimization responsive
                      id={"productsPanelGrid"}
                      ref={this._productsPanelGridRef}
                      minWidth="auto"
                      pagination={{pageSize: 200, dataPerPageVariants: [25, 50, 100, 200]}}
                      hovering={GridHoveringType.Row}
                      className={this.css(styles.productsPanelGrid, styles.stickyHeader)}
                      headerMinHeight={80}
                      odd={GridOddType.None}
                      borderType={BorderType.NoSeparators}
                      columns={this._columns}
                      noDataText={Localizer.productPanelGridNoProducts}
                      initRow={(row: RowModel<OrderProduct>) => this.initRow(row)}
                      fetchData={(sender: Grid<OrderProduct>, pageNumber, pageSize, sortColumnName, sortDirection) => this.fetchAsync(sender, sortColumnName, sortDirection)}
                />

                <ProductModal id={"productModal"}
                              ref={this._productModalRef}
                />

            </div>
        )
    }
}