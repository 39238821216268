// autogenerated
import {ProductReplacementLevel} from "@/models/Enums";
import CustomerGroup from "@/models/server/CustomerGroup";
import Customer from "@/models/server/Customer";
import Product from "@/models/server/Product";
import User from "@/models/server/User";
import ProductReplacementInfo from "@/models/server/ProductReplacementInfo";

export default class ProductReplacement extends ProductReplacementInfo {

    public customerGroupId: string | null = null;

    public customerGroup: CustomerGroup | null = null;

    public customerId: string | null = null;

    public customer: Customer | null = null;

    public fromProductId: string = "";

    public fromProduct: Product | null = null;

    public toProductId: string = "";

    public toProduct: Product | null = null;

    public level: ProductReplacementLevel = ProductReplacementLevel.Global;

    public generateLabels: boolean = false;

    public deleteGroupAssortments: boolean = false;

    public validTo: Date | null = null;

    public createdAt: Date = new Date();

    public createdBy: User | null = null;

    public deleted: boolean = false;

    public deleteAt: Date | null = null;

    public deleteBy: User | null = null;

    public owner: string | null = null;

    public readonly isProductReplacement: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ProductReplacement) || ((from as any).isProductReplacement === true));
    }

    public static as(from?: object | null): ProductReplacement | null {
        return (ProductReplacement.is(from)) ? from as ProductReplacement : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}